.vertical-layout.vertical-menu-modern .main-menu {
    transition: .4s cubic-bezier(.25, .8, .25, 1), background 0s;
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden
}

.vertical-layout.vertical-menu-modern .main-menu .navigation li a {
    align-items: center
}

.vertical-layout.vertical-menu-modern .main-menu .navigation>li>a i, .vertical-layout.vertical-menu-modern .main-menu .navigation>li>a svg {
    height: 20px;
    width: 20px;
    font-size: 1.45rem;
    margin-right: 1.1rem;
    flex-shrink: 0
}

.vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content>li>a i, .vertical-layout.vertical-menu-modern .main-menu .navigation .menu-content>li>a svg {
    margin-right: 1.45rem;
    font-size: 11px;
    height: 11px;
    width: 11px
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu {
    width: 260px
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation .navigation-header .feather-more-horizontal {
    display: none
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation>li>a>i:before, .vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation>li>a>svg:before {
    height: 20px;
    width: 20px;
    font-size: 1.45rem
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub>a:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 1.1rem;
    height: 1.1rem;
    width: 1.1rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 14px;
    transform: rotate(0deg);
    transition: all .2s ease-out
}

.vertical-layout.vertical-menu-modern.menu-expanded .main-menu .navigation li.has-sub.open:not(.menu-item-closing)>a:after {
    transform: rotate(90deg)
}

.vertical-layout.vertical-menu-modern.menu-expanded .footer {
    margin-left: 260px
}

.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header {
    float: left;
    width: 80px
}

.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header .modern-nav-toggle {
    display: none
}

.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header.expanded {
    width: 260px;
    z-index: 1000
}

.vertical-layout.vertical-menu-modern.menu-collapsed .navbar .navbar-header.expanded .modern-nav-toggle {
    display: block
}

.vertical-layout.vertical-menu-modern.menu-collapsed .navbar.fixed-top, .vertical-layout.vertical-menu-modern.menu-collapsed .navbar.floating-nav {
    left: 80px
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
    width: 80px
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .modern-nav-toggle, .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navbar-header .brand-text {
    display: none
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header {
    margin-left: 2.2rem
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header span {
    display: none
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation-header .feather-more-horizontal {
    display: block;
    font-size: 1.285rem;
    width: 18px;
    height: 18px
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li:last-child {
    margin-bottom: 1.25rem !important
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu:not(.expanded) .navigation li.active a {
    background: #f5f5f5;
    box-shadow: none;
    color: #565656
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded {
    width: 260px
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation>li.navigation-header span {
    display: block
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation>li.navigation-header .feather-more-horizontal {
    display: none
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub>a:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 1rem;
    height: 1rem;
    width: 1rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 14px;
    transform: rotate(0deg);
    transition: all .2s ease-out
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navigation li.has-sub.open:not(.menu-item-closing)>a:after {
    transform: rotate(90deg)
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .navbar-header .brand-text {
    display: inline
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu.expanded .modern-nav-toggle {
    display: block
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation {
    overflow: visible
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation>li.navigation-header span {
    display: none
}

.vertical-layout.vertical-menu-modern.menu-collapsed .main-menu .navigation>li>a {
    text-overflow: inherit
}

.vertical-layout.vertical-menu-modern.menu-collapsed .app-content, .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
    margin-left: 80px
}

.vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.floating-nav {
    width: calc(100vw - (100vw - 100%) - 4.4rem - 74px)
}

.vertical-layout.vertical-menu-modern.menu-collapsed .header-navbar.navbar-static-top {
    width: calc(100vw - (100vw - 100%) - 74px);
    left: 74px
}

.vertical-layout.vertical-menu-modern .collapse-toggle-icon, .vertical-layout.vertical-menu-modern .toggle-icon {
    margin-right: .425rem
}

.vertical-layout.vertical-menu-modern .collapse-toggle-icon:focus, .vertical-layout.vertical-menu-modern .toggle-icon:focus {
    outline: none
}

@media (min-width:992px) {
    .vertical-layout.vertical-menu-modern .main-menu {
        width: 260px
    }
}

@media (max-width:1199.98px) {
    .vertical-layout.vertical-menu-modern .main-menu, .vertical-layout.vertical-menu-modern .navbar .navbar-header {
        width: 0
    }

    .vertical-layout.vertical-menu-modern .content, .vertical-layout.vertical-menu-modern .footer, .vertical-layout.vertical-menu-modern.menu-collapsed .app-content, .vertical-layout.vertical-menu-modern.menu-collapsed .footer {
        margin-left: 0
    }

    .vertical-layout.vertical-menu-modern.menu-collapsed .main-menu {
        width: 0
    }
}

@media (max-width:767.98px) {
    .vertical-layout.vertical-menu-modern .main-menu, .vertical-layout.vertical-menu-modern .navbar .navbar-header {
        width: 0
    }

    .vertical-layout.vertical-menu-modern .content, .vertical-layout.vertical-menu-modern .footer {
        margin-left: 0
    }
}

@-webkit-keyframes fadein {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes fadein {
    0% {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@-webkit-keyframes fadeout {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes fadeout {
    0% {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@media (-ms-high-contrast:none), screen and (-ms-high-contrast:active) {
    .vertical-menu-modern.vertical-layout .main-menu .navigation>li>a>span {
        -webkit-animation: none;
        animation: none
    }
}

.vertical-overlay-menu .content {
    margin-left: 0
}

.vertical-overlay-menu .navbar .navbar-header {
    float: left;
    width: 260px
}

.vertical-overlay-menu .main-menu, .vertical-overlay-menu.menu-hide .main-menu {
    opacity: 0;
    transform: translateZ(0);
    transition: width .25s, opacity .25s, transform .25s;
    width: 260px;
    left: -260px
}

.vertical-overlay-menu .main-menu .navigation>li>a>i, .vertical-overlay-menu .main-menu .navigation>li>a>svg {
    margin-right: 14px;
    float: left;
    transition: all .2s ease;
    height: 20px;
    width: 20px
}

.vertical-overlay-menu .main-menu .navigation>li>a>i:before, .vertical-overlay-menu .main-menu .navigation>li>a>svg:before {
    transition: all .2s ease;
    font-size: 1.429rem
}

.vertical-overlay-menu .main-menu .navigation li.has-sub>a:after {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%236e6b7b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 1rem;
    height: 1rem;
    width: 1rem;
    display: inline-block;
    position: absolute;
    right: 20px;
    top: 14px;
    transform: rotate(0deg);
    transition: all .2s ease-out
}

.vertical-overlay-menu .main-menu .navigation li.has-sub.open:not(.menu-item-closing)>a:after {
    transform: rotate(90deg)
}

.vertical-overlay-menu .main-menu .navigation .navigation-header .feather-more-horizontal {
    display: none
}

.vertical-overlay-menu.menu-open .main-menu {
    opacity: 1;
    transform: translate3d(260px, 0, 0);
    transition: width .25s, opacity .25s, transform .25s
}

.ngx-datatable {
    display: block;
    overflow: hidden;
    justify-content: center;
    position: relative;
    transform: translateZ(0)
}

.ngx-datatable [hidden] {
    display: none !important
}

.ngx-datatable *, .ngx-datatable :after, .ngx-datatable :before {
    box-sizing: border-box
}

.ngx-datatable.scroll-vertical .datatable-body {
    overflow-y: auto
}

.ngx-datatable.scroll-vertical.virtualized .datatable-body .datatable-row-wrapper {
    position: absolute
}

.ngx-datatable.scroll-horz .datatable-body {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner {
    white-space: nowrap
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.ngx-datatable.fixed-row .datatable-scroll, .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
    white-space: nowrap
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell, .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.ngx-datatable .datatable-body-row, .ngx-datatable .datatable-header-inner, .ngx-datatable .datatable-row-center {
    display: flex;
    flex-direction: row;
    -o-flex-flow: row;
    flex-flow: row
}

.ngx-datatable .datatable-body-cell, .ngx-datatable .datatable-header-cell {
    overflow-x: hidden;
    vertical-align: top;
    display: inline-block;
    line-height: 1.625
}

.ngx-datatable .datatable-body-cell:focus, .ngx-datatable .datatable-header-cell:focus {
    outline: none
}

.ngx-datatable .datatable-row-left, .ngx-datatable .datatable-row-right {
    z-index: 9
}

.ngx-datatable .datatable-row-center, .ngx-datatable .datatable-row-group, .ngx-datatable .datatable-row-left, .ngx-datatable .datatable-row-right {
    position: relative
}

.ngx-datatable .datatable-header {
    display: block;
    overflow: hidden
}

.ngx-datatable .datatable-header .datatable-header-inner {
    align-items: stretch;
    -webkit-align-items: stretch
}

.ngx-datatable .datatable-header .datatable-header-cell {
    position: relative;
    display: inline-block
}

.ngx-datatable .datatable-header .datatable-header-cell.sortable .datatable-header-cell-wrapper {
    cursor: pointer
}

.ngx-datatable .datatable-header .datatable-header-cell.longpress .datatable-header-cell-wrapper {
    cursor: move
}

.ngx-datatable .datatable-header .datatable-header-cell .sort-btn {
    line-height: 100%;
    vertical-align: middle;
    display: inline-block;
    cursor: pointer
}

.ngx-datatable .datatable-header .datatable-header-cell .resize-handle, .ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 5px;
    padding: 0 4px;
    visibility: hidden
}

.ngx-datatable .datatable-header .datatable-header-cell .resize-handle {
    cursor: ew-resize
}

.ngx-datatable .datatable-header .datatable-header-cell.resizeable:hover .resize-handle, .ngx-datatable .datatable-header .datatable-header-cell:hover .resize-handle--not-resizable {
    visibility: visible
}

.ngx-datatable .datatable-header .datatable-header-cell .targetMarker {
    position: absolute;
    top: 0;
    bottom: 0
}

.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromLeft {
    right: 0
}

.ngx-datatable .datatable-header .datatable-header-cell .targetMarker.dragFromRight {
    left: 0
}

.ngx-datatable .datatable-header .datatable-header-cell .datatable-header-cell-template-wrap {
    height: inherit
}

.ngx-datatable .datatable-body {
    position: relative;
    z-index: 10;
    display: block
}

.ngx-datatable .datatable-body .datatable-scroll {
    display: inline-block
}

.ngx-datatable .datatable-body .datatable-row-detail {
    overflow-y: hidden
}

.ngx-datatable .datatable-body .datatable-row-wrapper {
    display: flex;
    flex-direction: column
}

.ngx-datatable .datatable-body .datatable-body-row {
    outline: none
}

.ngx-datatable .datatable-body .datatable-body-row>div {
    display: flex
}

.ngx-datatable .datatable-footer {
    display: block;
    width: 100%;
    overflow: auto
}

.ngx-datatable .datatable-footer .datatable-footer-inner {
    display: flex;
    align-items: center;
    width: 100%
}

.ngx-datatable .datatable-footer .selected-count .page-count {
    flex: 1 1 40%
}

.ngx-datatable .datatable-footer .selected-count .datatable-pager {
    flex: 1 1 60%
}

.ngx-datatable .datatable-footer .page-count {
    flex: 1 1 20%
}

.ngx-datatable .datatable-footer .datatable-pager {
    flex: 1 1 80%;
    text-align: right
}

.ngx-datatable .datatable-footer .datatable-pager .pager, .ngx-datatable .datatable-footer .datatable-pager .pager li {
    padding: 0;
    margin: 0;
    display: inline-block;
    list-style: none
}

.ngx-datatable .datatable-footer .datatable-pager .pager li, .ngx-datatable .datatable-footer .datatable-pager .pager li a {
    outline: none
}

.ngx-datatable .datatable-footer .datatable-pager .pager li a {
    cursor: pointer;
    display: inline-block
}

.ngx-datatable .datatable-footer .datatable-pager .pager li.disabled a {
    cursor: not-allowed
}

[data-icon]:before {
    content: attr(data-icon)
}

[class*=" datatable-icon-"]:before, [class^=datatable-icon-]:before, [data-icon]:before {
    font-family: data-table !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-feature-settings: normal !important;
    font-variant: normal !important;
    text-transform: none !important;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.datatable-icon-filter:before {
    content: "\62"
}

.datatable-icon-collapse:before {
    content: "\61"
}

.datatable-icon-expand:before {
    content: "\63"
}

.datatable-icon-close:before {
    content: "\64"
}

.datatable-icon-up:before {
    content: "\65"
}

.datatable-icon-down:before {
    content: "\66"
}

.datatable-icon-sort-unset:before {
    content: "\63";
    opacity: .5
}

.datatable-icon-sort:before {
    content: "\67"
}

.datatable-icon-done:before {
    content: "\68"
}

.datatable-icon-done-all:before {
    content: "\69"
}

.datatable-icon-search:before {
    content: "\6a"
}

.datatable-icon-pin:before {
    content: "\6b"
}

.datatable-icon-add:before {
    content: "\6d"
}

.datatable-icon-left:before {
    content: "\6f"
}

.datatable-icon-right:before {
    content: "\70"
}

.datatable-icon-skip:before {
    content: "\71"
}

.datatable-icon-prev:before {
    content: "\72"
}

.ngx-datatable.bootstrap {
    box-shadow: none;
    font-size: 13px
}

.ngx-datatable.bootstrap .datatable-header {
    height: unset !important
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell {
    vertical-align: bottom;
    padding: .75rem;
    border-bottom: 1px solid #d1d4d7
}

.ngx-datatable.bootstrap .datatable-header .datatable-header-cell .datatable-header-cell-label {
    line-height: 24px
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row {
    vertical-align: top;
    border-top: 1px solid #d1d4d7
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.datatable-row-even {
    background-color: rgba(0, 0, 0, .05)
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row.active {
    background-color: #1483ff;
    color: #fff
}

.ngx-datatable.bootstrap .datatable-body .datatable-body-row .datatable-body-cell {
    padding: .75rem;
    text-align: left;
    vertical-align: top
}

.ngx-datatable.bootstrap .datatable-body .empty-row {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 0
}

.ngx-datatable.bootstrap .datatable-footer {
    background: #424242;
    color: #ededed;
    margin-top: -1px
}

.ngx-datatable.bootstrap .datatable-footer .page-count {
    line-height: 50px;
    height: 50px;
    padding: 0 1.2rem
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager {
    margin: 0 10px;
    vertical-align: top
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li {
    margin: 10px 0
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a, .ngx-datatable.bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
    background-color: #545454;
    font-weight: 700
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager a {
    height: 22px;
    min-width: 24px;
    line-height: 22px;
    padding: 0;
    border-radius: 3px;
    margin: 0 3px;
    text-align: center;
    vertical-align: top;
    text-decoration: none;
    vertical-align: bottom;
    color: #ededed
}

.ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-left, .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-prev, .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-right, .ngx-datatable.bootstrap .datatable-footer .datatable-pager .datatable-icon-skip {
    font-size: 18px;
    line-height: 27px;
    padding: 0 3px
}

.ngx-datatable.bootstrap .datatable-summary-row .datatable-body-row .datatable-body-cell {
    font-weight: 700
}

.bg-white {
    background-color: #fff !important
}

.bg-white .card-footer, .bg-white .card-header {
    background-color: transparent
}

.border-white {
    border: 1px solid #fff !important
}

.border-top-white {
    border-top: 1px solid #fff
}

.border-bottom-white {
    border-bottom: 1px solid #fff
}

.border-left-white {
    border-left: 1px solid #fff
}

.border-right-white {
    border-right: 1px solid #fff
}

.badge-white.badge-glow, .bg-white.badge-glow, .border-white.badge-glow {
    box-shadow: 0 0 10px #fff
}

.overlay-white {
    background: #fff;
    background: hsla(0, 0%, 100%, .6)
}

input:focus~.bg-white {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #fff !important
}

.bg-black {
    background-color: #000 !important
}

.bg-black .card-footer, .bg-black .card-header {
    background-color: transparent
}

.border-black {
    border: 1px solid #000 !important
}

.border-top-black {
    border-top: 1px solid #000
}

.border-bottom-black {
    border-bottom: 1px solid #000
}

.border-left-black {
    border-left: 1px solid #000
}

.border-right-black {
    border-right: 1px solid #000
}

.badge-black.badge-glow, .bg-black.badge-glow, .border-black.badge-glow {
    box-shadow: 0 0 10px #000
}

.overlay-black {
    background: #000;
    background: rgba(0, 0, 0, .6)
}

input:focus~.bg-black {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #000 !important
}

.bg-dark {
    background-color: #4b4b4b !important
}

.bg-dark .card-footer, .bg-dark .card-header {
    background-color: transparent
}

.alert-dark {
    background: rgba(75, 75, 75, .12) !important;
    color: #4b4b4b !important
}

.alert-dark .alert-heading {
    box-shadow: 0 6px 15px -7px rgba(75, 75, 75, .4)
}

.alert-dark .alert-link {
    color: #3e3e3e !important
}

.alert-dark .close {
    color: #4b4b4b !important
}

.border-dark {
    border: 1px solid #4b4b4b !important
}

.border-top-dark {
    border-top: 1px solid #4b4b4b
}

.border-bottom-dark {
    border-bottom: 1px solid #4b4b4b
}

.border-left-dark {
    border-left: 1px solid #4b4b4b
}

.border-right-dark {
    border-right: 1px solid #4b4b4b
}

.badge-dark.badge-glow, .bg-dark.badge-glow, .border-dark.badge-glow {
    box-shadow: 0 0 10px #4b4b4b
}

.badge.badge-light-dark {
    background-color: rgba(75, 75, 75, .12);
    color: #4b4b4b !important
}

.overlay-dark {
    background: #4b4b4b;
    background: rgba(75, 75, 75, .6)
}

.btn-dark {
    border-color: #4b4b4b !important;
    background-color: #4b4b4b !important;
    color: #fff !important
}

.btn-dark.active, .btn-dark:active, .btn-dark:focus {
    color: #fff;
    background-color: #343434 !important
}

.btn-dark:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #4b4b4b
}

.btn-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-flat-dark {
    background-color: transparent;
    color: #4b4b4b
}

.btn-flat-dark:hover {
    color: #4b4b4b
}

.btn-flat-dark:hover:not(.disabled):not(:disabled) {
    background-color: rgba(75, 75, 75, .12)
}

.btn-flat-dark.active, .btn-flat-dark:active, .btn-flat-dark:focus {
    background-color: rgba(75, 75, 75, .2);
    color: #4b4b4b
}

.btn-flat-dark.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%234b4b4b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.btn-relief-dark {
    background-color: #4b4b4b;
    box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, .2);
    color: #fff;
    transition: all .2s ease
}

.btn-relief-dark:hover:not(.disabled):not(:disabled) {
    background-color: #626262
}

.btn-relief-dark.active, .btn-relief-dark:active, .btn-relief-dark:focus {
    background-color: #343434
}

.btn-relief-dark:hover {
    color: #fff
}

.btn-relief-dark.active, .btn-relief-dark:active {
    outline: none;
    box-shadow: none;
    transform: translateY(3px)
}

.btn-outline-dark {
    border: 1px solid #4b4b4b !important;
    background-color: transparent;
    color: #4b4b4b
}

.btn-outline-dark:hover:not(.disabled):not(:disabled) {
    background-color: rgba(75, 75, 75, .04);
    color: #4b4b4b
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled):focus {
    background-color: rgba(75, 75, 75, .2);
    color: #4b4b4b
}

.btn-outline-dark.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%234b4b4b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.show>.btn-outline-dark.dropdown-toggle {
    background-color: rgba(75, 75, 75, .2);
    color: #4b4b4b
}

.btn-flat-dark.waves-effect .waves-ripple, .btn-outline-dark.waves-effect .waves-ripple {
    background: radial-gradient(rgba(75, 75, 75, .2) 0, rgba(75, 75, 75, .3) 40%, rgba(75, 75, 75, .4) 50%, rgba(75, 75, 75, .5) 60%, hsla(0, 0%, 100%, 0) 70%)
}

.bullet.bullet-dark {
    background-color: #4b4b4b
}

.modal.modal-dark .modal-header .modal-title {
    color: #4b4b4b
}

.modal.modal-dark .modal-header .close {
    color: #4b4b4b !important
}

.progress-bar-dark {
    background-color: rgba(75, 75, 75, .12)
}

.progress-bar-dark .progress-bar {
    background-color: #4b4b4b
}

.timeline .timeline-point-dark {
    border-color: #4b4b4b !important
}

.timeline .timeline-point-dark i, .timeline .timeline-point-dark svg {
    stroke: #4b4b4b !important
}

.timeline .timeline-point-dark.timeline-point-indicator {
    background-color: #4b4b4b !important
}

.timeline .timeline-point-dark.timeline-point-indicator:before {
    background: rgba(75, 75, 75, .12) !important
}

.divider.divider-dark .divider-text:after, .divider.divider-dark .divider-text:before {
    border-color: #4b4b4b !important
}

input:focus~.bg-dark {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #4b4b4b !important
}

.custom-control-dark .custom-control-input:active~.custom-control-label:before, .custom-control-dark .custom-control-input:checked~.custom-control-label:before {
    border-color: #4b4b4b;
    background-color: #4b4b4b
}

.custom-control-dark.custom-checkbox .custom-control-input:active~.custom-control-label:before, .custom-control-dark.custom-checkbox .custom-control-input:checked~.custom-control-label:before, .custom-control-dark.custom-checkbox .custom-control-input:focus~.custom-control-label:before, .custom-control-dark.custom-radio .custom-control-input:active~.custom-control-label:before, .custom-control-dark.custom-radio .custom-control-input:checked~.custom-control-label:before, .custom-control-dark.custom-radio .custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 2px 4px 0 rgba(75, 75, 75, .4) !important
}

.custom-control-dark .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(75, 75, 75, .65) !important;
    border: none;
    box-shadow: none !important
}

.custom-control-dark .custom-control-input:focus~.custom-control-label:before {
    border-color: #4b4b4b !important
}

.custom-switch-dark .custom-control-input:checked~.custom-control-label:before {
    background-color: #4b4b4b !important;
    color: #fff;
    transition: all .2s ease-out
}

.select2-dark .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #4b4b4b !important;
    border-color: #4b4b4b !important
}

.text-dark.text-darken-1 {
    color: #343434 !important
}

.bg-dark.bg-darken-1 {
    background-color: #343434 !important
}

.border-dark.border-darken-1 {
    border: 1px solid #343434 !important
}

.border-top-dark.border-top-darken-1 {
    border-top: 1px solid #343434 !important
}

.border-bottom-dark.border-bottom-darken-1 {
    border-bottom: 1px solid #343434 !important
}

.border-left-dark.border-left-darken-1 {
    border-left: 1px solid #343434 !important
}

.border-right-dark.border-right-darken-1 {
    border-right: 1px solid #343434 !important
}

.overlay-dark.overlay-darken-1 {
    background: #343434;
    background: rgba(52, 52, 52, .6)
}

.text-dark.text-darken-2 {
    color: #1e1e1e !important
}

.bg-dark.bg-darken-2 {
    background-color: #1e1e1e !important
}

.border-dark.border-darken-2 {
    border: 1px solid #1e1e1e !important
}

.border-top-dark.border-top-darken-2 {
    border-top: 1px solid #1e1e1e !important
}

.border-bottom-dark.border-bottom-darken-2 {
    border-bottom: 1px solid #1e1e1e !important
}

.border-left-dark.border-left-darken-2 {
    border-left: 1px solid #1e1e1e !important
}

.border-right-dark.border-right-darken-2 {
    border-right: 1px solid #1e1e1e !important
}

.overlay-dark.overlay-darken-2 {
    background: #1e1e1e;
    background: rgba(30, 30, 30, .6)
}

.text-dark.text-darken-3 {
    color: #626262 !important
}

.bg-dark.bg-darken-3 {
    background-color: #626262 !important
}

.border-dark.border-darken-3 {
    border: 1px solid #626262 !important
}

.border-top-dark.border-top-darken-3 {
    border-top: 1px solid #626262 !important
}

.border-bottom-dark.border-bottom-darken-3 {
    border-bottom: 1px solid #626262 !important
}

.border-left-dark.border-left-darken-3 {
    border-left: 1px solid #626262 !important
}

.border-right-dark.border-right-darken-3 {
    border-right: 1px solid #626262 !important
}

.overlay-dark.overlay-darken-3 {
    background: #626262;
    background: rgba(98, 98, 98, .6)
}

.bg-light {
    background-color: #f6f6f6 !important
}

.bg-light .card-footer, .bg-light .card-header {
    background-color: transparent
}

.border-light {
    border: 1px solid #f6f6f6 !important
}

.border-top-light {
    border-top: 1px solid #f6f6f6
}

.border-bottom-light {
    border-bottom: 1px solid #f6f6f6
}

.border-left-light {
    border-left: 1px solid #f6f6f6
}

.border-right-light {
    border-right: 1px solid #f6f6f6
}

.badge-light.badge-glow, .bg-light.badge-glow, .border-light.badge-glow {
    box-shadow: 0 0 10px #f6f6f6
}

.overlay-light {
    background: #f6f6f6;
    background: hsla(0, 0%, 96%, .6)
}

input:focus~.bg-light {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #f6f6f6 !important
}

.text-primary.text-lighten-5 {
    color: #dedbfb !important
}

.bg-primary.bg-lighten-5 {
    background-color: #dedbfb !important
}

.border-primary.border-lighten-5 {
    border: 1px solid #dedbfb !important
}

.border-top-primary.border-top-lighten-5 {
    border-top: 1px solid #dedbfb !important
}

.border-bottom-primary.border-bottom-lighten-5 {
    border-bottom: 1px solid #dedbfb !important
}

.border-left-primary.border-left-lighten-5 {
    border-left: 1px solid #dedbfb !important
}

.border-right-primary.border-right-lighten-5 {
    border-right: 1px solid #dedbfb !important
}

.overlay-primary.overlay-lighten-5 {
    background: #dedbfb;
    background: rgba(222, 219, 251, .6)
}

.text-primary.text-lighten-4 {
    color: #c9c4f9 !important
}

.bg-primary.bg-lighten-4 {
    background-color: #c9c4f9 !important
}

.border-primary.border-lighten-4 {
    border: 1px solid #c9c4f9 !important
}

.border-top-primary.border-top-lighten-4 {
    border-top: 1px solid #c9c4f9 !important
}

.border-bottom-primary.border-bottom-lighten-4 {
    border-bottom: 1px solid #c9c4f9 !important
}

.border-left-primary.border-left-lighten-4 {
    border-left: 1px solid #c9c4f9 !important
}

.border-right-primary.border-right-lighten-4 {
    border-right: 1px solid #c9c4f9 !important
}

.overlay-primary.overlay-lighten-4 {
    background: #c9c4f9;
    background: rgba(201, 196, 249, .6)
}

.text-primary.text-lighten-3 {
    color: #b3adf7 !important
}

.bg-primary.bg-lighten-3 {
    background-color: #b3adf7 !important
}

.border-primary.border-lighten-3 {
    border: 1px solid #b3adf7 !important
}

.border-top-primary.border-top-lighten-3 {
    border-top: 1px solid #b3adf7 !important
}

.border-bottom-primary.border-bottom-lighten-3 {
    border-bottom: 1px solid #b3adf7 !important
}

.border-left-primary.border-left-lighten-3 {
    border-left: 1px solid #b3adf7 !important
}

.border-right-primary.border-right-lighten-3 {
    border-right: 1px solid #b3adf7 !important
}

.overlay-primary.overlay-lighten-3 {
    background: #b3adf7;
    background: rgba(179, 173, 247, .6)
}

.text-primary.text-lighten-2 {
    color: #9e95f5 !important
}

.bg-primary.bg-lighten-2 {
    background-color: #9e95f5 !important
}

.border-primary.border-lighten-2 {
    border: 1px solid #9e95f5 !important
}

.border-top-primary.border-top-lighten-2 {
    border-top: 1px solid #9e95f5 !important
}

.border-bottom-primary.border-bottom-lighten-2 {
    border-bottom: 1px solid #9e95f5 !important
}

.border-left-primary.border-left-lighten-2 {
    border-left: 1px solid #9e95f5 !important
}

.border-right-primary.border-right-lighten-2 {
    border-right: 1px solid #9e95f5 !important
}

.overlay-primary.overlay-lighten-2 {
    background: #9e95f5;
    background: rgba(158, 149, 245, .6)
}

.text-primary.text-lighten-1 {
    color: #887ef2 !important
}

.bg-primary.bg-lighten-1 {
    background-color: #887ef2 !important
}

.border-primary.border-lighten-1 {
    border: 1px solid #887ef2 !important
}

.border-top-primary.border-top-lighten-1 {
    border-top: 1px solid #887ef2 !important
}

.border-bottom-primary.border-bottom-lighten-1 {
    border-bottom: 1px solid #887ef2 !important
}

.border-left-primary.border-left-lighten-1 {
    border-left: 1px solid #887ef2 !important
}

.border-right-primary.border-right-lighten-1 {
    border-right: 1px solid #887ef2 !important
}

.overlay-primary.overlay-lighten-1 {
    background: #887ef2;
    background: rgba(136, 126, 242, .6)
}

.bg-primary {
    background-color: #7367f0 !important
}

.bg-primary .card-footer, .bg-primary .card-header {
    background-color: transparent
}

.alert-primary {
    background: rgba(115, 103, 240, .12) !important;
    color: #7367f0 !important
}

.alert-primary .alert-heading {
    box-shadow: 0 6px 15px -7px rgba(115, 103, 240, .4)
}

.alert-primary .alert-link {
    color: #5e50ee !important
}

.alert-primary .close, .bg-light-primary {
    color: #7367f0 !important
}

.bg-light-primary {
    background: rgba(115, 103, 240, .12) !important
}

.bg-light-primary.fc-h-event, .bg-light-primary.fc-v-event {
    border-color: rgba(115, 103, 240, .1)
}

.bg-light-primary .fc-daygrid-event-dot, .bg-light-primary .fc-list-event-dot {
    border-color: #7367f0 !important
}

.bg-light-primary.fc-list-event:hover td {
    background: rgba(115, 103, 240, .1) !important
}

.bg-light-primary.fc-list-event .fc-list-event-title {
    color: #6e6b7b
}

.avatar.bg-light-primary {
    color: #7367f0 !important
}

.border-primary {
    border: 1px solid #7367f0 !important
}

.border-top-primary {
    border-top: 1px solid #7367f0
}

.border-bottom-primary {
    border-bottom: 1px solid #7367f0
}

.border-left-primary {
    border-left: 1px solid #7367f0
}

.border-right-primary {
    border-right: 1px solid #7367f0
}

.badge-primary.badge-glow, .bg-primary.badge-glow, .border-primary.badge-glow {
    box-shadow: 0 0 10px #7367f0
}

.badge.badge-light-primary {
    background-color: rgba(115, 103, 240, .12);
    color: #7367f0 !important
}

.overlay-primary {
    background: #7367f0;
    background: rgba(115, 103, 240, .6)
}

.btn-primary {
    border-color: #7367f0 !important;
    background-color: #7367f0 !important;
    color: #fff !important
}

.btn-primary.active, .btn-primary:active, .btn-primary:focus {
    color: #fff;
    background-color: #5e50ee !important
}

.btn-primary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #7367f0
}

.btn-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-flat-primary {
    background-color: transparent;
    color: #7367f0
}

.btn-flat-primary:hover {
    color: #7367f0
}

.btn-flat-primary:hover:not(.disabled):not(:disabled) {
    background-color: rgba(115, 103, 240, .12)
}

.btn-flat-primary.active, .btn-flat-primary:active, .btn-flat-primary:focus {
    background-color: rgba(115, 103, 240, .2);
    color: #7367f0
}

.btn-flat-primary.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.btn-relief-primary {
    background-color: #7367f0;
    box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, .2);
    color: #fff;
    transition: all .2s ease
}

.btn-relief-primary:hover:not(.disabled):not(:disabled) {
    background-color: #887ef2
}

.btn-relief-primary.active, .btn-relief-primary:active, .btn-relief-primary:focus {
    background-color: #5e50ee
}

.btn-relief-primary:hover {
    color: #fff
}

.btn-relief-primary.active, .btn-relief-primary:active {
    outline: none;
    box-shadow: none;
    transform: translateY(3px)
}

.btn-outline-primary {
    border: 1px solid #7367f0 !important;
    background-color: transparent;
    color: #7367f0
}

.btn-outline-primary:hover:not(.disabled):not(:disabled) {
    background-color: rgba(115, 103, 240, .04);
    color: #7367f0
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled):focus {
    background-color: rgba(115, 103, 240, .2);
    color: #7367f0
}

.btn-outline-primary.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.show>.btn-outline-primary.dropdown-toggle {
    background-color: rgba(115, 103, 240, .2);
    color: #7367f0
}

.btn-flat-primary.waves-effect .waves-ripple, .btn-outline-primary.waves-effect .waves-ripple {
    background: radial-gradient(rgba(115, 103, 240, .2) 0, rgba(115, 103, 240, .3) 40%, rgba(115, 103, 240, .4) 50%, rgba(115, 103, 240, .5) 60%, hsla(0, 0%, 100%, 0) 70%)
}

.bullet.bullet-primary {
    background-color: #7367f0
}

.modal.modal-primary .modal-header .modal-title {
    color: #7367f0
}

.modal.modal-primary .modal-header .close {
    color: #7367f0 !important
}

.pagination-primary .page-item.active .page-link {
    background: #7367f0 !important;
    color: #fff
}

.pagination-primary .page-item.active .page-link:hover {
    color: #fff
}

.pagination-primary .page-item .page-link:hover {
    color: #7367f0
}

.pagination-primary .page-item.next-item .page-link:hover, .pagination-primary .page-item.prev-item .page-link:hover {
    background: #7367f0;
    color: #fff
}

.pagination-primary .page-item.next-item .page-link:active:after, .pagination-primary .page-item.next-item .page-link:hover:after, .pagination-primary .page-item.next .page-link:active:after, .pagination-primary .page-item.next .page-link:hover:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.pagination-primary .page-item.prev-item .page-link:active:before, .pagination-primary .page-item.prev-item .page-link:hover:before, .pagination-primary .page-item.prev .page-link:active:before, .pagination-primary .page-item.prev .page-link:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%237367f0' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.nav-pill-primary .nav-item .nav-link.active {
    color: #fff;
    background-color: #7367f0 !important;
    border-color: #7367f0;
    box-shadow: 0 4px 18px -4px rgba(115, 103, 240, .65)
}

.progress-bar-primary {
    background-color: rgba(115, 103, 240, .12)
}

.progress-bar-primary .progress-bar {
    background-color: #7367f0
}

.timeline .timeline-point-primary {
    border-color: #7367f0 !important
}

.timeline .timeline-point-primary i, .timeline .timeline-point-primary svg {
    stroke: #7367f0 !important
}

.timeline .timeline-point-primary.timeline-point-indicator {
    background-color: #7367f0 !important
}

.timeline .timeline-point-primary.timeline-point-indicator:before {
    background: rgba(115, 103, 240, .12) !important
}

.divider.divider-primary .divider-text:after, .divider.divider-primary .divider-text:before {
    border-color: #7367f0 !important
}

input:focus~.bg-primary {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #7367f0 !important
}

.custom-control-primary .custom-control-input:active~.custom-control-label:before, .custom-control-primary .custom-control-input:checked~.custom-control-label:before {
    border-color: #7367f0;
    background-color: #7367f0
}

.custom-control-primary.custom-checkbox .custom-control-input:active~.custom-control-label:before, .custom-control-primary.custom-checkbox .custom-control-input:checked~.custom-control-label:before, .custom-control-primary.custom-checkbox .custom-control-input:focus~.custom-control-label:before, .custom-control-primary.custom-radio .custom-control-input:active~.custom-control-label:before, .custom-control-primary.custom-radio .custom-control-input:checked~.custom-control-label:before, .custom-control-primary.custom-radio .custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 2px 4px 0 rgba(115, 103, 240, .4) !important
}

.custom-control-primary .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(115, 103, 240, .65) !important;
    border: none;
    box-shadow: none !important
}

.custom-control-primary .custom-control-input:focus~.custom-control-label:before {
    border-color: #7367f0 !important
}

.custom-switch-primary .custom-control-input:checked~.custom-control-label:before {
    background-color: #7367f0 !important;
    color: #fff;
    transition: all .2s ease-out
}

.select2-primary .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #7367f0 !important;
    border-color: #7367f0 !important
}

.text-primary.text-darken-1 {
    color: #5e50ee !important
}

.bg-primary.bg-darken-1 {
    background-color: #5e50ee !important
}

.border-primary.border-darken-1 {
    border: 1px solid #5e50ee !important
}

.border-top-primary.border-top-darken-1 {
    border-top: 1px solid #5e50ee !important
}

.border-bottom-primary.border-bottom-darken-1 {
    border-bottom: 1px solid #5e50ee !important
}

.border-left-primary.border-left-darken-1 {
    border-left: 1px solid #5e50ee !important
}

.border-right-primary.border-right-darken-1 {
    border-right: 1px solid #5e50ee !important
}

.overlay-primary.overlay-darken-1 {
    background: #5e50ee;
    background: rgba(94, 80, 238, .6)
}

.text-primary.text-darken-2 {
    color: #4839eb !important
}

.bg-primary.bg-darken-2 {
    background-color: #4839eb !important
}

.border-primary.border-darken-2 {
    border: 1px solid #4839eb !important
}

.border-top-primary.border-top-darken-2 {
    border-top: 1px solid #4839eb !important
}

.border-bottom-primary.border-bottom-darken-2 {
    border-bottom: 1px solid #4839eb !important
}

.border-left-primary.border-left-darken-2 {
    border-left: 1px solid #4839eb !important
}

.border-right-primary.border-right-darken-2 {
    border-right: 1px solid #4839eb !important
}

.overlay-primary.overlay-darken-2 {
    background: #4839eb;
    background: rgba(72, 57, 235, .6)
}

.text-primary.text-darken-3 {
    color: #3321e9 !important
}

.bg-primary.bg-darken-3 {
    background-color: #3321e9 !important
}

.border-primary.border-darken-3 {
    border: 1px solid #3321e9 !important
}

.border-top-primary.border-top-darken-3 {
    border-top: 1px solid #3321e9 !important
}

.border-bottom-primary.border-bottom-darken-3 {
    border-bottom: 1px solid #3321e9 !important
}

.border-left-primary.border-left-darken-3 {
    border-left: 1px solid #3321e9 !important
}

.border-right-primary.border-right-darken-3 {
    border-right: 1px solid #3321e9 !important
}

.overlay-primary.overlay-darken-3 {
    background: #3321e9;
    background: rgba(51, 33, 233, .6)
}

.text-primary.text-darken-4 {
    color: #2716db !important
}

.bg-primary.bg-darken-4 {
    background-color: #2716db !important
}

.border-primary.border-darken-4 {
    border: 1px solid #2716db !important
}

.border-top-primary.border-top-darken-4 {
    border-top: 1px solid #2716db !important
}

.border-bottom-primary.border-bottom-darken-4 {
    border-bottom: 1px solid #2716db !important
}

.border-left-primary.border-left-darken-4 {
    border-left: 1px solid #2716db !important
}

.border-right-primary.border-right-darken-4 {
    border-right: 1px solid #2716db !important
}

.overlay-primary.overlay-darken-4 {
    background: #2716db;
    background: rgba(39, 22, 219, .6)
}

.text-primary.text-accent-1 {
    color: #bdfdff !important
}

.bg-primary.bg-accent-1 {
    background-color: #bdfdff !important
}

.border-primary.border-accent-1 {
    border: 1px solid #bdfdff !important
}

.border-top-primary.border-top-accent-1 {
    border-top: 1px solid #bdfdff !important
}

.border-bottom-primary.border-bottom-accent-1 {
    border-bottom: 1px solid #bdfdff !important
}

.border-left-primary.border-left-accent-1 {
    border-left: 1px solid #bdfdff !important
}

.border-right-primary.border-right-accent-1 {
    border-right: 1px solid #bdfdff !important
}

.overlay-primary.overlay-accent-1 {
    background: #bdfdff;
    background: rgba(189, 253, 255, .6)
}

.text-primary.text-accent-2 {
    color: #8afbff !important
}

.bg-primary.bg-accent-2 {
    background-color: #8afbff !important
}

.border-primary.border-accent-2 {
    border: 1px solid #8afbff !important
}

.border-top-primary.border-top-accent-2 {
    border-top: 1px solid #8afbff !important
}

.border-bottom-primary.border-bottom-accent-2 {
    border-bottom: 1px solid #8afbff !important
}

.border-left-primary.border-left-accent-2 {
    border-left: 1px solid #8afbff !important
}

.border-right-primary.border-right-accent-2 {
    border-right: 1px solid #8afbff !important
}

.overlay-primary.overlay-accent-2 {
    background: #8afbff;
    background: rgba(138, 251, 255, .6)
}

.text-primary.text-accent-3 {
    color: #57faff !important
}

.bg-primary.bg-accent-3 {
    background-color: #57faff !important
}

.border-primary.border-accent-3 {
    border: 1px solid #57faff !important
}

.border-top-primary.border-top-accent-3 {
    border-top: 1px solid #57faff !important
}

.border-bottom-primary.border-bottom-accent-3 {
    border-bottom: 1px solid #57faff !important
}

.border-left-primary.border-left-accent-3 {
    border-left: 1px solid #57faff !important
}

.border-right-primary.border-right-accent-3 {
    border-right: 1px solid #57faff !important
}

.overlay-primary.overlay-accent-3 {
    background: #57faff;
    background: rgba(87, 250, 255, .6)
}

.text-primary.text-accent-4 {
    color: #3df9ff !important
}

.bg-primary.bg-accent-4 {
    background-color: #3df9ff !important
}

.border-primary.border-accent-4 {
    border: 1px solid #3df9ff !important
}

.border-top-primary.border-top-accent-4 {
    border-top: 1px solid #3df9ff !important
}

.border-bottom-primary.border-bottom-accent-4 {
    border-bottom: 1px solid #3df9ff !important
}

.border-left-primary.border-left-accent-4 {
    border-left: 1px solid #3df9ff !important
}

.border-right-primary.border-right-accent-4 {
    border-right: 1px solid #3df9ff !important
}

.overlay-primary.overlay-accent-4 {
    background: #3df9ff;
    background: rgba(61, 249, 255, .6)
}

.text-secondary.text-lighten-5 {
    color: #c4c6c8 !important
}

.bg-secondary.bg-lighten-5 {
    background-color: #c4c6c8 !important
}

.border-secondary.border-lighten-5 {
    border: 1px solid #c4c6c8 !important
}

.border-top-secondary.border-top-lighten-5 {
    border-top: 1px solid #c4c6c8 !important
}

.border-bottom-secondary.border-bottom-lighten-5 {
    border-bottom: 1px solid #c4c6c8 !important
}

.border-left-secondary.border-left-lighten-5 {
    border-left: 1px solid #c4c6c8 !important
}

.border-right-secondary.border-right-lighten-5 {
    border-right: 1px solid #c4c6c8 !important
}

.overlay-secondary.overlay-lighten-5 {
    background: #c4c6c8;
    background: hsla(210, 4%, 78%, .6)
}

.text-secondary.text-lighten-4 {
    color: #b7b9bc !important
}

.bg-secondary.bg-lighten-4 {
    background-color: #b7b9bc !important
}

.border-secondary.border-lighten-4 {
    border: 1px solid #b7b9bc !important
}

.border-top-secondary.border-top-lighten-4 {
    border-top: 1px solid #b7b9bc !important
}

.border-bottom-secondary.border-bottom-lighten-4 {
    border-bottom: 1px solid #b7b9bc !important
}

.border-left-secondary.border-left-lighten-4 {
    border-left: 1px solid #b7b9bc !important
}

.border-right-secondary.border-right-lighten-4 {
    border-right: 1px solid #b7b9bc !important
}

.overlay-secondary.overlay-lighten-4 {
    background: #b7b9bc;
    background: hsla(216, 4%, 73%, .6)
}

.text-secondary.text-lighten-3 {
    color: #aaacb0 !important
}

.bg-secondary.bg-lighten-3 {
    background-color: #aaacb0 !important
}

.border-secondary.border-lighten-3 {
    border: 1px solid #aaacb0 !important
}

.border-top-secondary.border-top-lighten-3 {
    border-top: 1px solid #aaacb0 !important
}

.border-bottom-secondary.border-bottom-lighten-3 {
    border-bottom: 1px solid #aaacb0 !important
}

.border-left-secondary.border-left-lighten-3 {
    border-left: 1px solid #aaacb0 !important
}

.border-right-secondary.border-right-lighten-3 {
    border-right: 1px solid #aaacb0 !important
}

.overlay-secondary.overlay-lighten-3 {
    background: #aaacb0;
    background: hsla(220, 4%, 68%, .6)
}

.text-secondary.text-lighten-2 {
    color: #9ca0a4 !important
}

.bg-secondary.bg-lighten-2 {
    background-color: #9ca0a4 !important
}

.border-secondary.border-lighten-2 {
    border: 1px solid #9ca0a4 !important
}

.border-top-secondary.border-top-lighten-2 {
    border-top: 1px solid #9ca0a4 !important
}

.border-bottom-secondary.border-bottom-lighten-2 {
    border-bottom: 1px solid #9ca0a4 !important
}

.border-left-secondary.border-left-lighten-2 {
    border-left: 1px solid #9ca0a4 !important
}

.border-right-secondary.border-right-lighten-2 {
    border-right: 1px solid #9ca0a4 !important
}

.overlay-secondary.overlay-lighten-2 {
    background: #9ca0a4;
    background: hsla(210, 4%, 63%, .6)
}

.text-secondary.text-lighten-1 {
    color: #8f9397 !important
}

.bg-secondary.bg-lighten-1 {
    background-color: #8f9397 !important
}

.border-secondary.border-lighten-1 {
    border: 1px solid #8f9397 !important
}

.border-top-secondary.border-top-lighten-1 {
    border-top: 1px solid #8f9397 !important
}

.border-bottom-secondary.border-bottom-lighten-1 {
    border-bottom: 1px solid #8f9397 !important
}

.border-left-secondary.border-left-lighten-1 {
    border-left: 1px solid #8f9397 !important
}

.border-right-secondary.border-right-lighten-1 {
    border-right: 1px solid #8f9397 !important
}

.overlay-secondary.overlay-lighten-1 {
    background: #8f9397;
    background: hsla(210, 4%, 58%, .6)
}

.bg-secondary {
    background-color: #82868b !important
}

.bg-secondary .card-footer, .bg-secondary .card-header {
    background-color: transparent
}

.alert-secondary {
    background: hsla(213, 4%, 53%, .12) !important;
    color: #82868b !important
}

.alert-secondary .alert-heading {
    box-shadow: 0 6px 15px -7px hsla(213, 4%, 53%, .4)
}

.alert-secondary .alert-link {
    color: #75797e !important
}

.alert-secondary .close, .bg-light-secondary {
    color: #82868b !important
}

.bg-light-secondary {
    background: hsla(213, 4%, 53%, .12) !important
}

.bg-light-secondary.fc-h-event, .bg-light-secondary.fc-v-event {
    border-color: hsla(213, 4%, 53%, .1)
}

.bg-light-secondary .fc-daygrid-event-dot, .bg-light-secondary .fc-list-event-dot {
    border-color: #82868b !important
}

.bg-light-secondary.fc-list-event:hover td {
    background: hsla(213, 4%, 53%, .1) !important
}

.bg-light-secondary.fc-list-event .fc-list-event-title {
    color: #6e6b7b
}

.avatar.bg-light-secondary {
    color: #82868b !important
}

.border-secondary {
    border: 1px solid #82868b !important
}

.border-top-secondary {
    border-top: 1px solid #82868b
}

.border-bottom-secondary {
    border-bottom: 1px solid #82868b
}

.border-left-secondary {
    border-left: 1px solid #82868b
}

.border-right-secondary {
    border-right: 1px solid #82868b
}

.badge-secondary.badge-glow, .bg-secondary.badge-glow, .border-secondary.badge-glow {
    box-shadow: 0 0 10px #82868b
}

.badge.badge-light-secondary {
    background-color: hsla(213, 4%, 53%, .12);
    color: #82868b !important
}

.overlay-secondary {
    background: #82868b;
    background: hsla(213, 4%, 53%, .6)
}

.btn-secondary {
    border-color: #82868b !important;
    background-color: #82868b !important;
    color: #fff !important
}

.btn-secondary.active, .btn-secondary:active, .btn-secondary:focus {
    color: #fff;
    background-color: #75797e !important
}

.btn-secondary:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #82868b
}

.btn-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-flat-secondary {
    background-color: transparent;
    color: #82868b
}

.btn-flat-secondary:hover {
    color: #82868b
}

.btn-flat-secondary:hover:not(.disabled):not(:disabled) {
    background-color: hsla(213, 4%, 53%, .12)
}

.btn-flat-secondary.active, .btn-flat-secondary:active, .btn-flat-secondary:focus {
    background-color: hsla(213, 4%, 53%, .2);
    color: #82868b
}

.btn-flat-secondary.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2382868b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.btn-relief-secondary {
    background-color: #82868b;
    box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, .2);
    color: #fff;
    transition: all .2s ease
}

.btn-relief-secondary:hover:not(.disabled):not(:disabled) {
    background-color: #8f9397
}

.btn-relief-secondary.active, .btn-relief-secondary:active, .btn-relief-secondary:focus {
    background-color: #75797e
}

.btn-relief-secondary:hover {
    color: #fff
}

.btn-relief-secondary.active, .btn-relief-secondary:active {
    outline: none;
    box-shadow: none;
    transform: translateY(3px)
}

.btn-outline-secondary {
    border: 1px solid #82868b !important;
    background-color: transparent;
    color: #82868b
}

.btn-outline-secondary:hover:not(.disabled):not(:disabled) {
    background-color: hsla(213, 4%, 53%, .04);
    color: #82868b
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled):focus {
    background-color: hsla(213, 4%, 53%, .2);
    color: #82868b
}

.btn-outline-secondary.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2382868b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.show>.btn-outline-secondary.dropdown-toggle {
    background-color: hsla(213, 4%, 53%, .2);
    color: #82868b
}

.btn-flat-secondary.waves-effect .waves-ripple, .btn-outline-secondary.waves-effect .waves-ripple {
    background: radial-gradient(hsla(213, 4%, 53%, .2) 0, hsla(213, 4%, 53%, .3) 40%, hsla(213, 4%, 53%, .4) 50%, hsla(213, 4%, 53%, .5) 60%, hsla(0, 0%, 100%, 0) 70%)
}

.bullet.bullet-secondary {
    background-color: #82868b
}

.modal.modal-secondary .modal-header .modal-title {
    color: #82868b
}

.modal.modal-secondary .modal-header .close {
    color: #82868b !important
}

.pagination-secondary .page-item.active .page-link {
    background: #82868b !important;
    color: #fff
}

.pagination-secondary .page-item.active .page-link:hover {
    color: #fff
}

.pagination-secondary .page-item .page-link:hover {
    color: #82868b
}

.pagination-secondary .page-item.next-item .page-link:hover, .pagination-secondary .page-item.prev-item .page-link:hover {
    background: #82868b;
    color: #fff
}

.pagination-secondary .page-item.next-item .page-link:active:after, .pagination-secondary .page-item.next-item .page-link:hover:after, .pagination-secondary .page-item.next .page-link:active:after, .pagination-secondary .page-item.next .page-link:hover:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2382868b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.pagination-secondary .page-item.prev-item .page-link:active:before, .pagination-secondary .page-item.prev-item .page-link:hover:before, .pagination-secondary .page-item.prev .page-link:active:before, .pagination-secondary .page-item.prev .page-link:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2382868b' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.nav-pill-secondary .nav-item .nav-link.active {
    color: #fff;
    background-color: #82868b !important;
    border-color: #82868b;
    box-shadow: 0 4px 18px -4px hsla(213, 4%, 53%, .65)
}

.progress-bar-secondary {
    background-color: hsla(213, 4%, 53%, .12)
}

.progress-bar-secondary .progress-bar {
    background-color: #82868b
}

.timeline .timeline-point-secondary {
    border-color: #82868b !important
}

.timeline .timeline-point-secondary i, .timeline .timeline-point-secondary svg {
    stroke: #82868b !important
}

.timeline .timeline-point-secondary.timeline-point-indicator {
    background-color: #82868b !important
}

.timeline .timeline-point-secondary.timeline-point-indicator:before {
    background: hsla(213, 4%, 53%, .12) !important
}

.divider.divider-secondary .divider-text:after, .divider.divider-secondary .divider-text:before {
    border-color: #82868b !important
}

input:focus~.bg-secondary {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #82868b !important
}

.custom-control-secondary .custom-control-input:active~.custom-control-label:before, .custom-control-secondary .custom-control-input:checked~.custom-control-label:before {
    border-color: #82868b;
    background-color: #82868b
}

.custom-control-secondary.custom-checkbox .custom-control-input:active~.custom-control-label:before, .custom-control-secondary.custom-checkbox .custom-control-input:checked~.custom-control-label:before, .custom-control-secondary.custom-checkbox .custom-control-input:focus~.custom-control-label:before, .custom-control-secondary.custom-radio .custom-control-input:active~.custom-control-label:before, .custom-control-secondary.custom-radio .custom-control-input:checked~.custom-control-label:before, .custom-control-secondary.custom-radio .custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 2px 4px 0 hsla(213, 4%, 53%, .4) !important
}

.custom-control-secondary .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: hsla(213, 4%, 53%, .65) !important;
    border: none;
    box-shadow: none !important
}

.custom-control-secondary .custom-control-input:focus~.custom-control-label:before {
    border-color: #82868b !important
}

.custom-switch-secondary .custom-control-input:checked~.custom-control-label:before {
    background-color: #82868b !important;
    color: #fff;
    transition: all .2s ease-out
}

.select2-secondary .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #82868b !important;
    border-color: #82868b !important
}

.text-secondary.text-darken-1 {
    color: #75797e !important
}

.bg-secondary.bg-darken-1 {
    background-color: #75797e !important
}

.border-secondary.border-darken-1 {
    border: 1px solid #75797e !important
}

.border-top-secondary.border-top-darken-1 {
    border-top: 1px solid #75797e !important
}

.border-bottom-secondary.border-bottom-darken-1 {
    border-bottom: 1px solid #75797e !important
}

.border-left-secondary.border-left-darken-1 {
    border-left: 1px solid #75797e !important
}

.border-right-secondary.border-right-darken-1 {
    border-right: 1px solid #75797e !important
}

.overlay-secondary.overlay-darken-1 {
    background: #75797e;
    background: hsla(213, 4%, 48%, .6)
}

.text-secondary.text-darken-2 {
    color: #696d71 !important
}

.bg-secondary.bg-darken-2 {
    background-color: #696d71 !important
}

.border-secondary.border-darken-2 {
    border: 1px solid #696d71 !important
}

.border-top-secondary.border-top-darken-2 {
    border-top: 1px solid #696d71 !important
}

.border-bottom-secondary.border-bottom-darken-2 {
    border-bottom: 1px solid #696d71 !important
}

.border-left-secondary.border-left-darken-2 {
    border-left: 1px solid #696d71 !important
}

.border-right-secondary.border-right-darken-2 {
    border-right: 1px solid #696d71 !important
}

.overlay-secondary.overlay-darken-2 {
    background: #696d71;
    background: hsla(210, 4%, 43%, .6)
}

.text-secondary.text-darken-3 {
    color: #5d6064 !important
}

.bg-secondary.bg-darken-3 {
    background-color: #5d6064 !important
}

.border-secondary.border-darken-3 {
    border: 1px solid #5d6064 !important
}

.border-top-secondary.border-top-darken-3 {
    border-top: 1px solid #5d6064 !important
}

.border-bottom-secondary.border-bottom-darken-3 {
    border-bottom: 1px solid #5d6064 !important
}

.border-left-secondary.border-left-darken-3 {
    border-left: 1px solid #5d6064 !important
}

.border-right-secondary.border-right-darken-3 {
    border-right: 1px solid #5d6064 !important
}

.overlay-secondary.overlay-darken-3 {
    background: #5d6064;
    background: rgba(93, 96, 100, .6)
}

.text-secondary.text-darken-4 {
    color: #505357 !important
}

.bg-secondary.bg-darken-4 {
    background-color: #505357 !important
}

.border-secondary.border-darken-4 {
    border: 1px solid #505357 !important
}

.border-top-secondary.border-top-darken-4 {
    border-top: 1px solid #505357 !important
}

.border-bottom-secondary.border-bottom-darken-4 {
    border-bottom: 1px solid #505357 !important
}

.border-left-secondary.border-left-darken-4 {
    border-left: 1px solid #505357 !important
}

.border-right-secondary.border-right-darken-4 {
    border-right: 1px solid #505357 !important
}

.overlay-secondary.overlay-darken-4 {
    background: #505357;
    background: rgba(80, 83, 87, .6)
}

.text-success.text-lighten-5 {
    color: #88e7b2 !important
}

.bg-success.bg-lighten-5 {
    background-color: #88e7b2 !important
}

.border-success.border-lighten-5 {
    border: 1px solid #88e7b2 !important
}

.border-top-success.border-top-lighten-5 {
    border-top: 1px solid #88e7b2 !important
}

.border-bottom-success.border-bottom-lighten-5 {
    border-bottom: 1px solid #88e7b2 !important
}

.border-left-success.border-left-lighten-5 {
    border-left: 1px solid #88e7b2 !important
}

.border-right-success.border-right-lighten-5 {
    border-right: 1px solid #88e7b2 !important
}

.overlay-success.overlay-lighten-5 {
    background: #88e7b2;
    background: rgba(136, 231, 178, .6)
}

.text-success.text-lighten-4 {
    color: #72e3a4 !important
}

.bg-success.bg-lighten-4 {
    background-color: #72e3a4 !important
}

.border-success.border-lighten-4 {
    border: 1px solid #72e3a4 !important
}

.border-top-success.border-top-lighten-4 {
    border-top: 1px solid #72e3a4 !important
}

.border-bottom-success.border-bottom-lighten-4 {
    border-bottom: 1px solid #72e3a4 !important
}

.border-left-success.border-left-lighten-4 {
    border-left: 1px solid #72e3a4 !important
}

.border-right-success.border-right-lighten-4 {
    border-right: 1px solid #72e3a4 !important
}

.overlay-success.overlay-lighten-4 {
    background: #72e3a4;
    background: rgba(114, 227, 164, .6)
}

.text-success.text-lighten-3 {
    color: #5dde97 !important
}

.bg-success.bg-lighten-3 {
    background-color: #5dde97 !important
}

.border-success.border-lighten-3 {
    border: 1px solid #5dde97 !important
}

.border-top-success.border-top-lighten-3 {
    border-top: 1px solid #5dde97 !important
}

.border-bottom-success.border-bottom-lighten-3 {
    border-bottom: 1px solid #5dde97 !important
}

.border-left-success.border-left-lighten-3 {
    border-left: 1px solid #5dde97 !important
}

.border-right-success.border-right-lighten-3 {
    border-right: 1px solid #5dde97 !important
}

.overlay-success.overlay-lighten-3 {
    background: #5dde97;
    background: rgba(93, 222, 151, .6)
}

.text-success.text-lighten-2 {
    color: #48da89 !important
}

.bg-success.bg-lighten-2 {
    background-color: #48da89 !important
}

.border-success.border-lighten-2 {
    border: 1px solid #48da89 !important
}

.border-top-success.border-top-lighten-2 {
    border-top: 1px solid #48da89 !important
}

.border-bottom-success.border-bottom-lighten-2 {
    border-bottom: 1px solid #48da89 !important
}

.border-left-success.border-left-lighten-2 {
    border-left: 1px solid #48da89 !important
}

.border-right-success.border-right-lighten-2 {
    border-right: 1px solid #48da89 !important
}

.overlay-success.overlay-lighten-2 {
    background: #48da89;
    background: rgba(72, 218, 137, .6)
}

.text-success.text-lighten-1 {
    color: #33d67c !important
}

.bg-success.bg-lighten-1 {
    background-color: #33d67c !important
}

.border-success.border-lighten-1 {
    border: 1px solid #33d67c !important
}

.border-top-success.border-top-lighten-1 {
    border-top: 1px solid #33d67c !important
}

.border-bottom-success.border-bottom-lighten-1 {
    border-bottom: 1px solid #33d67c !important
}

.border-left-success.border-left-lighten-1 {
    border-left: 1px solid #33d67c !important
}

.border-right-success.border-right-lighten-1 {
    border-right: 1px solid #33d67c !important
}

.overlay-success.overlay-lighten-1 {
    background: #33d67c;
    background: rgba(51, 214, 124, .6)
}

.bg-success {
    background-color: #28c76f !important
}

.bg-success .card-footer, .bg-success .card-header {
    background-color: transparent
}

.alert-success {
    background: rgba(40, 199, 111, .12) !important;
    color: #28c76f !important
}

.alert-success .alert-heading {
    box-shadow: 0 6px 15px -7px rgba(40, 199, 111, .4)
}

.alert-success .alert-link {
    color: #24b263 !important
}

.alert-success .close, .bg-light-success {
    color: #28c76f !important
}

.bg-light-success {
    background: rgba(40, 199, 111, .12) !important
}

.bg-light-success.fc-h-event, .bg-light-success.fc-v-event {
    border-color: rgba(40, 199, 111, .1)
}

.bg-light-success .fc-daygrid-event-dot, .bg-light-success .fc-list-event-dot {
    border-color: #28c76f !important
}

.bg-light-success.fc-list-event:hover td {
    background: rgba(40, 199, 111, .1) !important
}

.bg-light-success.fc-list-event .fc-list-event-title {
    color: #6e6b7b
}

.avatar.bg-light-success {
    color: #28c76f !important
}

.border-success {
    border: 1px solid #28c76f !important
}

.border-top-success {
    border-top: 1px solid #28c76f
}

.border-bottom-success {
    border-bottom: 1px solid #28c76f
}

.border-left-success {
    border-left: 1px solid #28c76f
}

.border-right-success {
    border-right: 1px solid #28c76f
}

.badge-success.badge-glow, .bg-success.badge-glow, .border-success.badge-glow {
    box-shadow: 0 0 10px #28c76f
}

.badge.badge-light-success {
    background-color: rgba(40, 199, 111, .12);
    color: #28c76f !important
}

.overlay-success {
    background: #28c76f;
    background: rgba(40, 199, 111, .6)
}

.btn-success {
    border-color: #28c76f !important;
    background-color: #28c76f !important;
    color: #fff !important
}

.btn-success.active, .btn-success:active, .btn-success:focus {
    color: #fff;
    background-color: #24b263 !important
}

.btn-success:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #28c76f
}

.btn-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-flat-success {
    background-color: transparent;
    color: #28c76f
}

.btn-flat-success:hover {
    color: #28c76f
}

.btn-flat-success:hover:not(.disabled):not(:disabled) {
    background-color: rgba(40, 199, 111, .12)
}

.btn-flat-success.active, .btn-flat-success:active, .btn-flat-success:focus {
    background-color: rgba(40, 199, 111, .2);
    color: #28c76f
}

.btn-flat-success.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.btn-relief-success {
    background-color: #28c76f;
    box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, .2);
    color: #fff;
    transition: all .2s ease
}

.btn-relief-success:hover:not(.disabled):not(:disabled) {
    background-color: #33d67c
}

.btn-relief-success.active, .btn-relief-success:active, .btn-relief-success:focus {
    background-color: #24b263
}

.btn-relief-success:hover {
    color: #fff
}

.btn-relief-success.active, .btn-relief-success:active {
    outline: none;
    box-shadow: none;
    transform: translateY(3px)
}

.btn-outline-success {
    border: 1px solid #28c76f !important;
    background-color: transparent;
    color: #28c76f
}

.btn-outline-success:hover:not(.disabled):not(:disabled) {
    background-color: rgba(40, 199, 111, .04);
    color: #28c76f
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled):focus {
    background-color: rgba(40, 199, 111, .2);
    color: #28c76f
}

.btn-outline-success.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.show>.btn-outline-success.dropdown-toggle {
    background-color: rgba(40, 199, 111, .2);
    color: #28c76f
}

.btn-flat-success.waves-effect .waves-ripple, .btn-outline-success.waves-effect .waves-ripple {
    background: radial-gradient(rgba(40, 199, 111, .2) 0, rgba(40, 199, 111, .3) 40%, rgba(40, 199, 111, .4) 50%, rgba(40, 199, 111, .5) 60%, hsla(0, 0%, 100%, 0) 70%)
}

.bullet.bullet-success {
    background-color: #28c76f
}

.modal.modal-success .modal-header .modal-title {
    color: #28c76f
}

.modal.modal-success .modal-header .close {
    color: #28c76f !important
}

.pagination-success .page-item.active .page-link {
    background: #28c76f !important;
    color: #fff
}

.pagination-success .page-item.active .page-link:hover {
    color: #fff
}

.pagination-success .page-item .page-link:hover {
    color: #28c76f
}

.pagination-success .page-item.next-item .page-link:hover, .pagination-success .page-item.prev-item .page-link:hover {
    background: #28c76f;
    color: #fff
}

.pagination-success .page-item.next-item .page-link:active:after, .pagination-success .page-item.next-item .page-link:hover:after, .pagination-success .page-item.next .page-link:active:after, .pagination-success .page-item.next .page-link:hover:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.pagination-success .page-item.prev-item .page-link:active:before, .pagination-success .page-item.prev-item .page-link:hover:before, .pagination-success .page-item.prev .page-link:active:before, .pagination-success .page-item.prev .page-link:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2328c76f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.nav-pill-success .nav-item .nav-link.active {
    color: #fff;
    background-color: #28c76f !important;
    border-color: #28c76f;
    box-shadow: 0 4px 18px -4px rgba(40, 199, 111, .65)
}

.progress-bar-success {
    background-color: rgba(40, 199, 111, .12)
}

.progress-bar-success .progress-bar {
    background-color: #28c76f
}

.timeline .timeline-point-success {
    border-color: #28c76f !important
}

.timeline .timeline-point-success i, .timeline .timeline-point-success svg {
    stroke: #28c76f !important
}

.timeline .timeline-point-success.timeline-point-indicator {
    background-color: #28c76f !important
}

.timeline .timeline-point-success.timeline-point-indicator:before {
    background: rgba(40, 199, 111, .12) !important
}

.divider.divider-success .divider-text:after, .divider.divider-success .divider-text:before {
    border-color: #28c76f !important
}

input:focus~.bg-success {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #28c76f !important
}

.custom-control-success .custom-control-input:active~.custom-control-label:before, .custom-control-success .custom-control-input:checked~.custom-control-label:before {
    border-color: #28c76f;
    background-color: #28c76f
}

.custom-control-success.custom-checkbox .custom-control-input:active~.custom-control-label:before, .custom-control-success.custom-checkbox .custom-control-input:checked~.custom-control-label:before, .custom-control-success.custom-checkbox .custom-control-input:focus~.custom-control-label:before, .custom-control-success.custom-radio .custom-control-input:active~.custom-control-label:before, .custom-control-success.custom-radio .custom-control-input:checked~.custom-control-label:before, .custom-control-success.custom-radio .custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 2px 4px 0 rgba(40, 199, 111, .4) !important
}

.custom-control-success .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(40, 199, 111, .65) !important;
    border: none;
    box-shadow: none !important
}

.custom-control-success .custom-control-input:focus~.custom-control-label:before {
    border-color: #28c76f !important
}

.custom-switch-success .custom-control-input:checked~.custom-control-label:before {
    background-color: #28c76f !important;
    color: #fff;
    transition: all .2s ease-out
}

.select2-success .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #28c76f !important;
    border-color: #28c76f !important
}

.text-success.text-darken-1 {
    color: #24b263 !important
}

.bg-success.bg-darken-1 {
    background-color: #24b263 !important
}

.border-success.border-darken-1 {
    border: 1px solid #24b263 !important
}

.border-top-success.border-top-darken-1 {
    border-top: 1px solid #24b263 !important
}

.border-bottom-success.border-bottom-darken-1 {
    border-bottom: 1px solid #24b263 !important
}

.border-left-success.border-left-darken-1 {
    border-left: 1px solid #24b263 !important
}

.border-right-success.border-right-darken-1 {
    border-right: 1px solid #24b263 !important
}

.overlay-success.overlay-darken-1 {
    background: #24b263;
    background: rgba(36, 178, 99, .6)
}

.text-success.text-darken-2 {
    color: #1f9d57 !important
}

.bg-success.bg-darken-2 {
    background-color: #1f9d57 !important
}

.border-success.border-darken-2 {
    border: 1px solid #1f9d57 !important
}

.border-top-success.border-top-darken-2 {
    border-top: 1px solid #1f9d57 !important
}

.border-bottom-success.border-bottom-darken-2 {
    border-bottom: 1px solid #1f9d57 !important
}

.border-left-success.border-left-darken-2 {
    border-left: 1px solid #1f9d57 !important
}

.border-right-success.border-right-darken-2 {
    border-right: 1px solid #1f9d57 !important
}

.overlay-success.overlay-darken-2 {
    background: #1f9d57;
    background: rgba(31, 157, 87, .6)
}

.text-success.text-darken-3 {
    color: #1b874b !important
}

.bg-success.bg-darken-3 {
    background-color: #1b874b !important
}

.border-success.border-darken-3 {
    border: 1px solid #1b874b !important
}

.border-top-success.border-top-darken-3 {
    border-top: 1px solid #1b874b !important
}

.border-bottom-success.border-bottom-darken-3 {
    border-bottom: 1px solid #1b874b !important
}

.border-left-success.border-left-darken-3 {
    border-left: 1px solid #1b874b !important
}

.border-right-success.border-right-darken-3 {
    border-right: 1px solid #1b874b !important
}

.overlay-success.overlay-darken-3 {
    background: #1b874b;
    background: rgba(27, 135, 75, .6)
}

.text-success.text-darken-4 {
    color: #177240 !important
}

.bg-success.bg-darken-4 {
    background-color: #177240 !important
}

.border-success.border-darken-4 {
    border: 1px solid #177240 !important
}

.border-top-success.border-top-darken-4 {
    border-top: 1px solid #177240 !important
}

.border-bottom-success.border-bottom-darken-4 {
    border-bottom: 1px solid #177240 !important
}

.border-left-success.border-left-darken-4 {
    border-left: 1px solid #177240 !important
}

.border-right-success.border-right-darken-4 {
    border-right: 1px solid #177240 !important
}

.overlay-success.overlay-darken-4 {
    background: #177240;
    background: rgba(23, 114, 64, .6)
}

.text-success.text-accent-1 {
    color: #e1fff1 !important
}

.bg-success.bg-accent-1 {
    background-color: #e1fff1 !important
}

.border-success.border-accent-1 {
    border: 1px solid #e1fff1 !important
}

.border-top-success.border-top-accent-1 {
    border-top: 1px solid #e1fff1 !important
}

.border-bottom-success.border-bottom-accent-1 {
    border-bottom: 1px solid #e1fff1 !important
}

.border-left-success.border-left-accent-1 {
    border-left: 1px solid #e1fff1 !important
}

.border-right-success.border-right-accent-1 {
    border-right: 1px solid #e1fff1 !important
}

.overlay-success.overlay-accent-1 {
    background: #e1fff1;
    background: rgba(225, 255, 241, .6)
}

.text-success.text-accent-2 {
    color: #aeffd9 !important
}

.bg-success.bg-accent-2 {
    background-color: #aeffd9 !important
}

.border-success.border-accent-2 {
    border: 1px solid #aeffd9 !important
}

.border-top-success.border-top-accent-2 {
    border-top: 1px solid #aeffd9 !important
}

.border-bottom-success.border-bottom-accent-2 {
    border-bottom: 1px solid #aeffd9 !important
}

.border-left-success.border-left-accent-2 {
    border-left: 1px solid #aeffd9 !important
}

.border-right-success.border-right-accent-2 {
    border-right: 1px solid #aeffd9 !important
}

.overlay-success.overlay-accent-2 {
    background: #aeffd9;
    background: rgba(174, 255, 217, .6)
}

.text-success.text-accent-3 {
    color: #7bffc1 !important
}

.bg-success.bg-accent-3 {
    background-color: #7bffc1 !important
}

.border-success.border-accent-3 {
    border: 1px solid #7bffc1 !important
}

.border-top-success.border-top-accent-3 {
    border-top: 1px solid #7bffc1 !important
}

.border-bottom-success.border-bottom-accent-3 {
    border-bottom: 1px solid #7bffc1 !important
}

.border-left-success.border-left-accent-3 {
    border-left: 1px solid #7bffc1 !important
}

.border-right-success.border-right-accent-3 {
    border-right: 1px solid #7bffc1 !important
}

.overlay-success.overlay-accent-3 {
    background: #7bffc1;
    background: rgba(123, 255, 193, .6)
}

.text-success.text-accent-4 {
    color: #62ffb5 !important
}

.bg-success.bg-accent-4 {
    background-color: #62ffb5 !important
}

.border-success.border-accent-4 {
    border: 1px solid #62ffb5 !important
}

.border-top-success.border-top-accent-4 {
    border-top: 1px solid #62ffb5 !important
}

.border-bottom-success.border-bottom-accent-4 {
    border-bottom: 1px solid #62ffb5 !important
}

.border-left-success.border-left-accent-4 {
    border-left: 1px solid #62ffb5 !important
}

.border-right-success.border-right-accent-4 {
    border-right: 1px solid #62ffb5 !important
}

.overlay-success.overlay-accent-4 {
    background: #62ffb5;
    background: rgba(98, 255, 181, .6)
}

.text-info.text-lighten-5 {
    color: #69efff !important
}

.bg-info.bg-lighten-5 {
    background-color: #69efff !important
}

.border-info.border-lighten-5 {
    border: 1px solid #69efff !important
}

.border-top-info.border-top-lighten-5 {
    border-top: 1px solid #69efff !important
}

.border-bottom-info.border-bottom-lighten-5 {
    border-bottom: 1px solid #69efff !important
}

.border-left-info.border-left-lighten-5 {
    border-left: 1px solid #69efff !important
}

.border-right-info.border-right-lighten-5 {
    border-right: 1px solid #69efff !important
}

.overlay-info.overlay-lighten-5 {
    background: #69efff;
    background: rgba(105, 239, 255, .6)
}

.text-info.text-lighten-4 {
    color: #4fecff !important
}

.bg-info.bg-lighten-4 {
    background-color: #4fecff !important
}

.border-info.border-lighten-4 {
    border: 1px solid #4fecff !important
}

.border-top-info.border-top-lighten-4 {
    border-top: 1px solid #4fecff !important
}

.border-bottom-info.border-bottom-lighten-4 {
    border-bottom: 1px solid #4fecff !important
}

.border-left-info.border-left-lighten-4 {
    border-left: 1px solid #4fecff !important
}

.border-right-info.border-right-lighten-4 {
    border-right: 1px solid #4fecff !important
}

.overlay-info.overlay-lighten-4 {
    background: #4fecff;
    background: rgba(79, 236, 255, .6)
}

.text-info.text-lighten-3 {
    color: #36e9ff !important
}

.bg-info.bg-lighten-3 {
    background-color: #36e9ff !important
}

.border-info.border-lighten-3 {
    border: 1px solid #36e9ff !important
}

.border-top-info.border-top-lighten-3 {
    border-top: 1px solid #36e9ff !important
}

.border-bottom-info.border-bottom-lighten-3 {
    border-bottom: 1px solid #36e9ff !important
}

.border-left-info.border-left-lighten-3 {
    border-left: 1px solid #36e9ff !important
}

.border-right-info.border-right-lighten-3 {
    border-right: 1px solid #36e9ff !important
}

.overlay-info.overlay-lighten-3 {
    background: #36e9ff;
    background: rgba(54, 233, 255, .6)
}

.text-info.text-lighten-2 {
    color: #1ce7ff !important
}

.bg-info.bg-lighten-2 {
    background-color: #1ce7ff !important
}

.border-info.border-lighten-2 {
    border: 1px solid #1ce7ff !important
}

.border-top-info.border-top-lighten-2 {
    border-top: 1px solid #1ce7ff !important
}

.border-bottom-info.border-bottom-lighten-2 {
    border-bottom: 1px solid #1ce7ff !important
}

.border-left-info.border-left-lighten-2 {
    border-left: 1px solid #1ce7ff !important
}

.border-right-info.border-right-lighten-2 {
    border-right: 1px solid #1ce7ff !important
}

.overlay-info.overlay-lighten-2 {
    background: #1ce7ff;
    background: rgba(28, 231, 255, .6)
}

.text-info.text-lighten-1 {
    color: #03e4ff !important
}

.bg-info.bg-lighten-1 {
    background-color: #03e4ff !important
}

.border-info.border-lighten-1 {
    border: 1px solid #03e4ff !important
}

.border-top-info.border-top-lighten-1 {
    border-top: 1px solid #03e4ff !important
}

.border-bottom-info.border-bottom-lighten-1 {
    border-bottom: 1px solid #03e4ff !important
}

.border-left-info.border-left-lighten-1 {
    border-left: 1px solid #03e4ff !important
}

.border-right-info.border-right-lighten-1 {
    border-right: 1px solid #03e4ff !important
}

.overlay-info.overlay-lighten-1 {
    background: #03e4ff;
    background: rgba(3, 228, 255, .6)
}

.bg-info {
    background-color: #00cfe8 !important
}

.bg-info .card-footer, .bg-info .card-header {
    background-color: transparent
}

.alert-info {
    background: rgba(0, 207, 232, .12) !important;
    color: #00cfe8 !important
}

.alert-info .alert-heading {
    box-shadow: 0 6px 15px -7px rgba(0, 207, 232, .4)
}

.alert-info .alert-link {
    color: #00b8cf !important
}

.alert-info .close, .bg-light-info {
    color: #00cfe8 !important
}

.bg-light-info {
    background: rgba(0, 207, 232, .12) !important
}

.bg-light-info.fc-h-event, .bg-light-info.fc-v-event {
    border-color: rgba(0, 207, 232, .1)
}

.bg-light-info .fc-daygrid-event-dot, .bg-light-info .fc-list-event-dot {
    border-color: #00cfe8 !important
}

.bg-light-info.fc-list-event:hover td {
    background: rgba(0, 207, 232, .1) !important
}

.bg-light-info.fc-list-event .fc-list-event-title {
    color: #6e6b7b
}

.avatar.bg-light-info {
    color: #00cfe8 !important
}

.border-info {
    border: 1px solid #00cfe8 !important
}

.border-top-info {
    border-top: 1px solid #00cfe8
}

.border-bottom-info {
    border-bottom: 1px solid #00cfe8
}

.border-left-info {
    border-left: 1px solid #00cfe8
}

.border-right-info {
    border-right: 1px solid #00cfe8
}

.badge-info.badge-glow, .bg-info.badge-glow, .border-info.badge-glow {
    box-shadow: 0 0 10px #00cfe8
}

.badge.badge-light-info {
    background-color: rgba(0, 207, 232, .12);
    color: #00cfe8 !important
}

.overlay-info {
    background: #00cfe8;
    background: rgba(0, 207, 232, .6)
}

.btn-info {
    border-color: #00cfe8 !important;
    background-color: #00cfe8 !important;
    color: #fff !important
}

.btn-info.active, .btn-info:active, .btn-info:focus {
    color: #fff;
    background-color: #00b8cf !important
}

.btn-info:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #00cfe8
}

.btn-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-flat-info {
    background-color: transparent;
    color: #00cfe8
}

.btn-flat-info:hover {
    color: #00cfe8
}

.btn-flat-info:hover:not(.disabled):not(:disabled) {
    background-color: rgba(0, 207, 232, .12)
}

.btn-flat-info.active, .btn-flat-info:active, .btn-flat-info:focus {
    background-color: rgba(0, 207, 232, .2);
    color: #00cfe8
}

.btn-flat-info.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300cfe8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.btn-relief-info {
    background-color: #00cfe8;
    box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, .2);
    color: #fff;
    transition: all .2s ease
}

.btn-relief-info:hover:not(.disabled):not(:disabled) {
    background-color: #03e4ff
}

.btn-relief-info.active, .btn-relief-info:active, .btn-relief-info:focus {
    background-color: #00b8cf
}

.btn-relief-info:hover {
    color: #fff
}

.btn-relief-info.active, .btn-relief-info:active {
    outline: none;
    box-shadow: none;
    transform: translateY(3px)
}

.btn-outline-info {
    border: 1px solid #00cfe8 !important;
    background-color: transparent;
    color: #00cfe8
}

.btn-outline-info:hover:not(.disabled):not(:disabled) {
    background-color: rgba(0, 207, 232, .04);
    color: #00cfe8
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled):focus {
    background-color: rgba(0, 207, 232, .2);
    color: #00cfe8
}

.btn-outline-info.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300cfe8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.show>.btn-outline-info.dropdown-toggle {
    background-color: rgba(0, 207, 232, .2);
    color: #00cfe8
}

.btn-flat-info.waves-effect .waves-ripple, .btn-outline-info.waves-effect .waves-ripple {
    background: radial-gradient(rgba(0, 207, 232, .2) 0, rgba(0, 207, 232, .3) 40%, rgba(0, 207, 232, .4) 50%, rgba(0, 207, 232, .5) 60%, hsla(0, 0%, 100%, 0) 70%)
}

.bullet.bullet-info {
    background-color: #00cfe8
}

.modal.modal-info .modal-header .modal-title {
    color: #00cfe8
}

.modal.modal-info .modal-header .close {
    color: #00cfe8 !important
}

.pagination-info .page-item.active .page-link {
    background: #00cfe8 !important;
    color: #fff
}

.pagination-info .page-item.active .page-link:hover {
    color: #fff
}

.pagination-info .page-item .page-link:hover {
    color: #00cfe8
}

.pagination-info .page-item.next-item .page-link:hover, .pagination-info .page-item.prev-item .page-link:hover {
    background: #00cfe8;
    color: #fff
}

.pagination-info .page-item.next-item .page-link:active:after, .pagination-info .page-item.next-item .page-link:hover:after, .pagination-info .page-item.next .page-link:active:after, .pagination-info .page-item.next .page-link:hover:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300cfe8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.pagination-info .page-item.prev-item .page-link:active:before, .pagination-info .page-item.prev-item .page-link:hover:before, .pagination-info .page-item.prev .page-link:active:before, .pagination-info .page-item.prev .page-link:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%2300cfe8' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.nav-pill-info .nav-item .nav-link.active {
    color: #fff;
    background-color: #00cfe8 !important;
    border-color: #00cfe8;
    box-shadow: 0 4px 18px -4px rgba(0, 207, 232, .65)
}

.progress-bar-info {
    background-color: rgba(0, 207, 232, .12)
}

.progress-bar-info .progress-bar {
    background-color: #00cfe8
}

.timeline .timeline-point-info {
    border-color: #00cfe8 !important
}

.timeline .timeline-point-info i, .timeline .timeline-point-info svg {
    stroke: #00cfe8 !important
}

.timeline .timeline-point-info.timeline-point-indicator {
    background-color: #00cfe8 !important
}

.timeline .timeline-point-info.timeline-point-indicator:before {
    background: rgba(0, 207, 232, .12) !important
}

.divider.divider-info .divider-text:after, .divider.divider-info .divider-text:before {
    border-color: #00cfe8 !important
}

input:focus~.bg-info {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #00cfe8 !important
}

.custom-control-info .custom-control-input:active~.custom-control-label:before, .custom-control-info .custom-control-input:checked~.custom-control-label:before {
    border-color: #00cfe8;
    background-color: #00cfe8
}

.custom-control-info.custom-checkbox .custom-control-input:active~.custom-control-label:before, .custom-control-info.custom-checkbox .custom-control-input:checked~.custom-control-label:before, .custom-control-info.custom-checkbox .custom-control-input:focus~.custom-control-label:before, .custom-control-info.custom-radio .custom-control-input:active~.custom-control-label:before, .custom-control-info.custom-radio .custom-control-input:checked~.custom-control-label:before, .custom-control-info.custom-radio .custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 2px 4px 0 rgba(0, 207, 232, .4) !important
}

.custom-control-info .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(0, 207, 232, .65) !important;
    border: none;
    box-shadow: none !important
}

.custom-control-info .custom-control-input:focus~.custom-control-label:before {
    border-color: #00cfe8 !important
}

.custom-switch-info .custom-control-input:checked~.custom-control-label:before {
    background-color: #00cfe8 !important;
    color: #fff;
    transition: all .2s ease-out
}

.select2-info .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #00cfe8 !important;
    border-color: #00cfe8 !important
}

.text-info.text-darken-1 {
    color: #00b8cf !important
}

.bg-info.bg-darken-1 {
    background-color: #00b8cf !important
}

.border-info.border-darken-1 {
    border: 1px solid #00b8cf !important
}

.border-top-info.border-top-darken-1 {
    border-top: 1px solid #00b8cf !important
}

.border-bottom-info.border-bottom-darken-1 {
    border-bottom: 1px solid #00b8cf !important
}

.border-left-info.border-left-darken-1 {
    border-left: 1px solid #00b8cf !important
}

.border-right-info.border-right-darken-1 {
    border-right: 1px solid #00b8cf !important
}

.overlay-info.overlay-darken-1 {
    background: #00b8cf;
    background: rgba(0, 184, 207, .6)
}

.text-info.text-darken-2 {
    color: #00a1b5 !important
}

.bg-info.bg-darken-2 {
    background-color: #00a1b5 !important
}

.border-info.border-darken-2 {
    border: 1px solid #00a1b5 !important
}

.border-top-info.border-top-darken-2 {
    border-top: 1px solid #00a1b5 !important
}

.border-bottom-info.border-bottom-darken-2 {
    border-bottom: 1px solid #00a1b5 !important
}

.border-left-info.border-left-darken-2 {
    border-left: 1px solid #00a1b5 !important
}

.border-right-info.border-right-darken-2 {
    border-right: 1px solid #00a1b5 !important
}

.overlay-info.overlay-darken-2 {
    background: #00a1b5;
    background: rgba(0, 161, 181, .6)
}

.text-info.text-darken-3 {
    color: #008b9c !important
}

.bg-info.bg-darken-3 {
    background-color: #008b9c !important
}

.border-info.border-darken-3 {
    border: 1px solid #008b9c !important
}

.border-top-info.border-top-darken-3 {
    border-top: 1px solid #008b9c !important
}

.border-bottom-info.border-bottom-darken-3 {
    border-bottom: 1px solid #008b9c !important
}

.border-left-info.border-left-darken-3 {
    border-left: 1px solid #008b9c !important
}

.border-right-info.border-right-darken-3 {
    border-right: 1px solid #008b9c !important
}

.overlay-info.overlay-darken-3 {
    background: #008b9c;
    background: rgba(0, 139, 156, .6)
}

.text-info.text-darken-4 {
    color: #007482 !important
}

.bg-info.bg-darken-4 {
    background-color: #007482 !important
}

.border-info.border-darken-4 {
    border: 1px solid #007482 !important
}

.border-top-info.border-top-darken-4 {
    border-top: 1px solid #007482 !important
}

.border-bottom-info.border-bottom-darken-4 {
    border-bottom: 1px solid #007482 !important
}

.border-left-info.border-left-darken-4 {
    border-left: 1px solid #007482 !important
}

.border-right-info.border-right-darken-4 {
    border-right: 1px solid #007482 !important
}

.overlay-info.overlay-darken-4 {
    background: #007482;
    background: rgba(0, 116, 130, .6)
}

.text-info.text-accent-1 {
    color: #feffff !important
}

.bg-info.bg-accent-1 {
    background-color: #feffff !important
}

.border-info.border-accent-1 {
    border: 1px solid #feffff !important
}

.border-top-info.border-top-accent-1 {
    border-top: 1px solid #feffff !important
}

.border-bottom-info.border-bottom-accent-1 {
    border-bottom: 1px solid #feffff !important
}

.border-left-info.border-left-accent-1 {
    border-left: 1px solid #feffff !important
}

.border-right-info.border-right-accent-1 {
    border-right: 1px solid #feffff !important
}

.overlay-info.overlay-accent-1 {
    background: #feffff;
    background: rgba(254, 255, 255, .6)
}

.text-info.text-accent-2 {
    color: #cbf5ff !important
}

.bg-info.bg-accent-2 {
    background-color: #cbf5ff !important
}

.border-info.border-accent-2 {
    border: 1px solid #cbf5ff !important
}

.border-top-info.border-top-accent-2 {
    border-top: 1px solid #cbf5ff !important
}

.border-bottom-info.border-bottom-accent-2 {
    border-bottom: 1px solid #cbf5ff !important
}

.border-left-info.border-left-accent-2 {
    border-left: 1px solid #cbf5ff !important
}

.border-right-info.border-right-accent-2 {
    border-right: 1px solid #cbf5ff !important
}

.overlay-info.overlay-accent-2 {
    background: #cbf5ff;
    background: rgba(203, 245, 255, .6)
}

.text-info.text-accent-3 {
    color: #98ecff !important
}

.bg-info.bg-accent-3 {
    background-color: #98ecff !important
}

.border-info.border-accent-3 {
    border: 1px solid #98ecff !important
}

.border-top-info.border-top-accent-3 {
    border-top: 1px solid #98ecff !important
}

.border-bottom-info.border-bottom-accent-3 {
    border-bottom: 1px solid #98ecff !important
}

.border-left-info.border-left-accent-3 {
    border-left: 1px solid #98ecff !important
}

.border-right-info.border-right-accent-3 {
    border-right: 1px solid #98ecff !important
}

.overlay-info.overlay-accent-3 {
    background: #98ecff;
    background: rgba(152, 236, 255, .6)
}

.text-info.text-accent-4 {
    color: #7fe7ff !important
}

.bg-info.bg-accent-4 {
    background-color: #7fe7ff !important
}

.border-info.border-accent-4 {
    border: 1px solid #7fe7ff !important
}

.border-top-info.border-top-accent-4 {
    border-top: 1px solid #7fe7ff !important
}

.border-bottom-info.border-bottom-accent-4 {
    border-bottom: 1px solid #7fe7ff !important
}

.border-left-info.border-left-accent-4 {
    border-left: 1px solid #7fe7ff !important
}

.border-right-info.border-right-accent-4 {
    border-right: 1px solid #7fe7ff !important
}

.overlay-info.overlay-accent-4 {
    background: #7fe7ff;
    background: rgba(127, 231, 255, .6)
}

.text-warning.text-lighten-5 {
    color: #ffe0c3 !important
}

.bg-warning.bg-lighten-5 {
    background-color: #ffe0c3 !important
}

.border-warning.border-lighten-5 {
    border: 1px solid #ffe0c3 !important
}

.border-top-warning.border-top-lighten-5 {
    border-top: 1px solid #ffe0c3 !important
}

.border-bottom-warning.border-bottom-lighten-5 {
    border-bottom: 1px solid #ffe0c3 !important
}

.border-left-warning.border-left-lighten-5 {
    border-left: 1px solid #ffe0c3 !important
}

.border-right-warning.border-right-lighten-5 {
    border-right: 1px solid #ffe0c3 !important
}

.overlay-warning.overlay-lighten-5 {
    background: #ffe0c3;
    background: rgba(255, 224, 195, .6)
}

.text-warning.text-lighten-4 {
    color: #ffd3a9 !important
}

.bg-warning.bg-lighten-4 {
    background-color: #ffd3a9 !important
}

.border-warning.border-lighten-4 {
    border: 1px solid #ffd3a9 !important
}

.border-top-warning.border-top-lighten-4 {
    border-top: 1px solid #ffd3a9 !important
}

.border-bottom-warning.border-bottom-lighten-4 {
    border-bottom: 1px solid #ffd3a9 !important
}

.border-left-warning.border-left-lighten-4 {
    border-left: 1px solid #ffd3a9 !important
}

.border-right-warning.border-right-lighten-4 {
    border-right: 1px solid #ffd3a9 !important
}

.overlay-warning.overlay-lighten-4 {
    background: #ffd3a9;
    background: rgba(255, 211, 169, .6)
}

.text-warning.text-lighten-3 {
    color: #ffc690 !important
}

.bg-warning.bg-lighten-3 {
    background-color: #ffc690 !important
}

.border-warning.border-lighten-3 {
    border: 1px solid #ffc690 !important
}

.border-top-warning.border-top-lighten-3 {
    border-top: 1px solid #ffc690 !important
}

.border-bottom-warning.border-bottom-lighten-3 {
    border-bottom: 1px solid #ffc690 !important
}

.border-left-warning.border-left-lighten-3 {
    border-left: 1px solid #ffc690 !important
}

.border-right-warning.border-right-lighten-3 {
    border-right: 1px solid #ffc690 !important
}

.overlay-warning.overlay-lighten-3 {
    background: #ffc690;
    background: rgba(255, 198, 144, .6)
}

.text-warning.text-lighten-2 {
    color: #ffb976 !important
}

.bg-warning.bg-lighten-2 {
    background-color: #ffb976 !important
}

.border-warning.border-lighten-2 {
    border: 1px solid #ffb976 !important
}

.border-top-warning.border-top-lighten-2 {
    border-top: 1px solid #ffb976 !important
}

.border-bottom-warning.border-bottom-lighten-2 {
    border-bottom: 1px solid #ffb976 !important
}

.border-left-warning.border-left-lighten-2 {
    border-left: 1px solid #ffb976 !important
}

.border-right-warning.border-right-lighten-2 {
    border-right: 1px solid #ffb976 !important
}

.overlay-warning.overlay-lighten-2 {
    background: #ffb976;
    background: rgba(255, 185, 118, .6)
}

.text-warning.text-lighten-1 {
    color: #ffac5d !important
}

.bg-warning.bg-lighten-1 {
    background-color: #ffac5d !important
}

.border-warning.border-lighten-1 {
    border: 1px solid #ffac5d !important
}

.border-top-warning.border-top-lighten-1 {
    border-top: 1px solid #ffac5d !important
}

.border-bottom-warning.border-bottom-lighten-1 {
    border-bottom: 1px solid #ffac5d !important
}

.border-left-warning.border-left-lighten-1 {
    border-left: 1px solid #ffac5d !important
}

.border-right-warning.border-right-lighten-1 {
    border-right: 1px solid #ffac5d !important
}

.overlay-warning.overlay-lighten-1 {
    background: #ffac5d;
    background: rgba(255, 172, 93, .6)
}

.bg-warning {
    background-color: #ff9f43 !important
}

.bg-warning .card-footer, .bg-warning .card-header {
    background-color: transparent
}

.alert-warning {
    background: rgba(255, 159, 67, .12) !important;
    color: #ff9f43 !important
}

.alert-warning .alert-heading {
    box-shadow: 0 6px 15px -7px rgba(255, 159, 67, .4)
}

.alert-warning .alert-link {
    color: #ff922a !important
}

.alert-warning .close, .bg-light-warning {
    color: #ff9f43 !important
}

.bg-light-warning {
    background: rgba(255, 159, 67, .12) !important
}

.bg-light-warning.fc-h-event, .bg-light-warning.fc-v-event {
    border-color: rgba(255, 159, 67, .1)
}

.bg-light-warning .fc-daygrid-event-dot, .bg-light-warning .fc-list-event-dot {
    border-color: #ff9f43 !important
}

.bg-light-warning.fc-list-event:hover td {
    background: rgba(255, 159, 67, .1) !important
}

.bg-light-warning.fc-list-event .fc-list-event-title {
    color: #6e6b7b
}

.avatar.bg-light-warning {
    color: #ff9f43 !important
}

.border-warning {
    border: 1px solid #ff9f43 !important
}

.border-top-warning {
    border-top: 1px solid #ff9f43
}

.border-bottom-warning {
    border-bottom: 1px solid #ff9f43
}

.border-left-warning {
    border-left: 1px solid #ff9f43
}

.border-right-warning {
    border-right: 1px solid #ff9f43
}

.badge-warning.badge-glow, .bg-warning.badge-glow, .border-warning.badge-glow {
    box-shadow: 0 0 10px #ff9f43
}

.badge.badge-light-warning {
    background-color: rgba(255, 159, 67, .12);
    color: #ff9f43 !important
}

.overlay-warning {
    background: #ff9f43;
    background: rgba(255, 159, 67, .6)
}

.btn-warning {
    border-color: #ff9f43 !important;
    background-color: #ff9f43 !important;
    color: #fff !important
}

.btn-warning.active, .btn-warning:active, .btn-warning:focus {
    color: #fff;
    background-color: #ff922a !important
}

.btn-warning:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #ff9f43
}

.btn-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-flat-warning {
    background-color: transparent;
    color: #ff9f43
}

.btn-flat-warning:hover {
    color: #ff9f43
}

.btn-flat-warning:hover:not(.disabled):not(:disabled) {
    background-color: rgba(255, 159, 67, .12)
}

.btn-flat-warning.active, .btn-flat-warning:active, .btn-flat-warning:focus {
    background-color: rgba(255, 159, 67, .2);
    color: #ff9f43
}

.btn-flat-warning.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff9f43' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.btn-relief-warning {
    background-color: #ff9f43;
    box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, .2);
    color: #fff;
    transition: all .2s ease
}

.btn-relief-warning:hover:not(.disabled):not(:disabled) {
    background-color: #ffac5d
}

.btn-relief-warning.active, .btn-relief-warning:active, .btn-relief-warning:focus {
    background-color: #ff922a
}

.btn-relief-warning:hover {
    color: #fff
}

.btn-relief-warning.active, .btn-relief-warning:active {
    outline: none;
    box-shadow: none;
    transform: translateY(3px)
}

.btn-outline-warning {
    border: 1px solid #ff9f43 !important;
    background-color: transparent;
    color: #ff9f43
}

.btn-outline-warning:hover:not(.disabled):not(:disabled) {
    background-color: rgba(255, 159, 67, .04);
    color: #ff9f43
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled):focus {
    background-color: rgba(255, 159, 67, .2);
    color: #ff9f43
}

.btn-outline-warning.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff9f43' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.show>.btn-outline-warning.dropdown-toggle {
    background-color: rgba(255, 159, 67, .2);
    color: #ff9f43
}

.btn-flat-warning.waves-effect .waves-ripple, .btn-outline-warning.waves-effect .waves-ripple {
    background: radial-gradient(rgba(255, 159, 67, .2) 0, rgba(255, 159, 67, .3) 40%, rgba(255, 159, 67, .4) 50%, rgba(255, 159, 67, .5) 60%, hsla(0, 0%, 100%, 0) 70%)
}

.bullet.bullet-warning {
    background-color: #ff9f43
}

.modal.modal-warning .modal-header .modal-title {
    color: #ff9f43
}

.modal.modal-warning .modal-header .close {
    color: #ff9f43 !important
}

.pagination-warning .page-item.active .page-link {
    background: #ff9f43 !important;
    color: #fff
}

.pagination-warning .page-item.active .page-link:hover {
    color: #fff
}

.pagination-warning .page-item .page-link:hover {
    color: #ff9f43
}

.pagination-warning .page-item.next-item .page-link:hover, .pagination-warning .page-item.prev-item .page-link:hover {
    background: #ff9f43;
    color: #fff
}

.pagination-warning .page-item.next-item .page-link:active:after, .pagination-warning .page-item.next-item .page-link:hover:after, .pagination-warning .page-item.next .page-link:active:after, .pagination-warning .page-item.next .page-link:hover:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff9f43' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.pagination-warning .page-item.prev-item .page-link:active:before, .pagination-warning .page-item.prev-item .page-link:hover:before, .pagination-warning .page-item.prev .page-link:active:before, .pagination-warning .page-item.prev .page-link:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ff9f43' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.nav-pill-warning .nav-item .nav-link.active {
    color: #fff;
    background-color: #ff9f43 !important;
    border-color: #ff9f43;
    box-shadow: 0 4px 18px -4px rgba(255, 159, 67, .65)
}

.progress-bar-warning {
    background-color: rgba(255, 159, 67, .12)
}

.progress-bar-warning .progress-bar {
    background-color: #ff9f43
}

.timeline .timeline-point-warning {
    border-color: #ff9f43 !important
}

.timeline .timeline-point-warning i, .timeline .timeline-point-warning svg {
    stroke: #ff9f43 !important
}

.timeline .timeline-point-warning.timeline-point-indicator {
    background-color: #ff9f43 !important
}

.timeline .timeline-point-warning.timeline-point-indicator:before {
    background: rgba(255, 159, 67, .12) !important
}

.divider.divider-warning .divider-text:after, .divider.divider-warning .divider-text:before {
    border-color: #ff9f43 !important
}

input:focus~.bg-warning {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ff9f43 !important
}

.custom-control-warning .custom-control-input:active~.custom-control-label:before, .custom-control-warning .custom-control-input:checked~.custom-control-label:before {
    border-color: #ff9f43;
    background-color: #ff9f43
}

.custom-control-warning.custom-checkbox .custom-control-input:active~.custom-control-label:before, .custom-control-warning.custom-checkbox .custom-control-input:checked~.custom-control-label:before, .custom-control-warning.custom-checkbox .custom-control-input:focus~.custom-control-label:before, .custom-control-warning.custom-radio .custom-control-input:active~.custom-control-label:before, .custom-control-warning.custom-radio .custom-control-input:checked~.custom-control-label:before, .custom-control-warning.custom-radio .custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 2px 4px 0 rgba(255, 159, 67, .4) !important
}

.custom-control-warning .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(255, 159, 67, .65) !important;
    border: none;
    box-shadow: none !important
}

.custom-control-warning .custom-control-input:focus~.custom-control-label:before {
    border-color: #ff9f43 !important
}

.custom-switch-warning .custom-control-input:checked~.custom-control-label:before {
    background-color: #ff9f43 !important;
    color: #fff;
    transition: all .2s ease-out
}

.select2-warning .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #ff9f43 !important;
    border-color: #ff9f43 !important
}

.text-warning.text-darken-1 {
    color: #ff922a !important
}

.bg-warning.bg-darken-1 {
    background-color: #ff922a !important
}

.border-warning.border-darken-1 {
    border: 1px solid #ff922a !important
}

.border-top-warning.border-top-darken-1 {
    border-top: 1px solid #ff922a !important
}

.border-bottom-warning.border-bottom-darken-1 {
    border-bottom: 1px solid #ff922a !important
}

.border-left-warning.border-left-darken-1 {
    border-left: 1px solid #ff922a !important
}

.border-right-warning.border-right-darken-1 {
    border-right: 1px solid #ff922a !important
}

.overlay-warning.overlay-darken-1 {
    background: #ff922a;
    background: rgba(255, 146, 42, .6)
}

.text-warning.text-darken-2 {
    color: #ff8510 !important
}

.bg-warning.bg-darken-2 {
    background-color: #ff8510 !important
}

.border-warning.border-darken-2 {
    border: 1px solid #ff8510 !important
}

.border-top-warning.border-top-darken-2 {
    border-top: 1px solid #ff8510 !important
}

.border-bottom-warning.border-bottom-darken-2 {
    border-bottom: 1px solid #ff8510 !important
}

.border-left-warning.border-left-darken-2 {
    border-left: 1px solid #ff8510 !important
}

.border-right-warning.border-right-darken-2 {
    border-right: 1px solid #ff8510 !important
}

.overlay-warning.overlay-darken-2 {
    background: #ff8510;
    background: rgba(255, 133, 16, .6)
}

.text-warning.text-darken-3 {
    color: #f67800 !important
}

.bg-warning.bg-darken-3 {
    background-color: #f67800 !important
}

.border-warning.border-darken-3 {
    border: 1px solid #f67800 !important
}

.border-top-warning.border-top-darken-3 {
    border-top: 1px solid #f67800 !important
}

.border-bottom-warning.border-bottom-darken-3 {
    border-bottom: 1px solid #f67800 !important
}

.border-left-warning.border-left-darken-3 {
    border-left: 1px solid #f67800 !important
}

.border-right-warning.border-right-darken-3 {
    border-right: 1px solid #f67800 !important
}

.overlay-warning.overlay-darken-3 {
    background: #f67800;
    background: rgba(246, 120, 0, .6)
}

.text-warning.text-darken-4 {
    color: #dc6c00 !important
}

.bg-warning.bg-darken-4 {
    background-color: #dc6c00 !important
}

.border-warning.border-darken-4 {
    border: 1px solid #dc6c00 !important
}

.border-top-warning.border-top-darken-4 {
    border-top: 1px solid #dc6c00 !important
}

.border-bottom-warning.border-bottom-darken-4 {
    border-bottom: 1px solid #dc6c00 !important
}

.border-left-warning.border-left-darken-4 {
    border-left: 1px solid #dc6c00 !important
}

.border-right-warning.border-right-darken-4 {
    border-right: 1px solid #dc6c00 !important
}

.overlay-warning.overlay-darken-4 {
    background: #dc6c00;
    background: rgba(220, 108, 0, .6)
}

.text-warning.text-accent-1 {
    color: #fff5ef !important
}

.bg-warning.bg-accent-1 {
    background-color: #fff5ef !important
}

.border-warning.border-accent-1 {
    border: 1px solid #fff5ef !important
}

.border-top-warning.border-top-accent-1 {
    border-top: 1px solid #fff5ef !important
}

.border-bottom-warning.border-bottom-accent-1 {
    border-bottom: 1px solid #fff5ef !important
}

.border-left-warning.border-left-accent-1 {
    border-left: 1px solid #fff5ef !important
}

.border-right-warning.border-right-accent-1 {
    border-right: 1px solid #fff5ef !important
}

.overlay-warning.overlay-accent-1 {
    background: #fff5ef;
    background: rgba(255, 245, 239, .6)
}

.text-warning.text-accent-2 {
    color: #ffe5d8 !important
}

.bg-warning.bg-accent-2 {
    background-color: #ffe5d8 !important
}

.border-warning.border-accent-2 {
    border: 1px solid #ffe5d8 !important
}

.border-top-warning.border-top-accent-2 {
    border-top: 1px solid #ffe5d8 !important
}

.border-bottom-warning.border-bottom-accent-2 {
    border-bottom: 1px solid #ffe5d8 !important
}

.border-left-warning.border-left-accent-2 {
    border-left: 1px solid #ffe5d8 !important
}

.border-right-warning.border-right-accent-2 {
    border-right: 1px solid #ffe5d8 !important
}

.overlay-warning.overlay-accent-2 {
    background: #ffe5d8;
    background: rgba(255, 229, 216, .6)
}

.text-warning.text-accent-3 {
    color: #fff6f3 !important
}

.bg-warning.bg-accent-3 {
    background-color: #fff6f3 !important
}

.border-warning.border-accent-3 {
    border: 1px solid #fff6f3 !important
}

.border-top-warning.border-top-accent-3 {
    border-top: 1px solid #fff6f3 !important
}

.border-bottom-warning.border-bottom-accent-3 {
    border-bottom: 1px solid #fff6f3 !important
}

.border-left-warning.border-left-accent-3 {
    border-left: 1px solid #fff6f3 !important
}

.border-right-warning.border-right-accent-3 {
    border-right: 1px solid #fff6f3 !important
}

.overlay-warning.overlay-accent-3 {
    background: #fff6f3;
    background: rgba(255, 246, 243, .6)
}

.text-warning.text-accent-4 {
    color: #ffe3da !important
}

.bg-warning.bg-accent-4 {
    background-color: #ffe3da !important
}

.border-warning.border-accent-4 {
    border: 1px solid #ffe3da !important
}

.border-top-warning.border-top-accent-4 {
    border-top: 1px solid #ffe3da !important
}

.border-bottom-warning.border-bottom-accent-4 {
    border-bottom: 1px solid #ffe3da !important
}

.border-left-warning.border-left-accent-4 {
    border-left: 1px solid #ffe3da !important
}

.border-right-warning.border-right-accent-4 {
    border-right: 1px solid #ffe3da !important
}

.overlay-warning.overlay-accent-4 {
    background: #ffe3da;
    background: rgba(255, 227, 218, .6)
}

.text-danger.text-lighten-5 {
    color: #f8c6c6 !important
}

.bg-danger.bg-lighten-5 {
    background-color: #f8c6c6 !important
}

.border-danger.border-lighten-5 {
    border: 1px solid #f8c6c6 !important
}

.border-top-danger.border-top-lighten-5 {
    border-top: 1px solid #f8c6c6 !important
}

.border-bottom-danger.border-bottom-lighten-5 {
    border-bottom: 1px solid #f8c6c6 !important
}

.border-left-danger.border-left-lighten-5 {
    border-left: 1px solid #f8c6c6 !important
}

.border-right-danger.border-right-lighten-5 {
    border-right: 1px solid #f8c6c6 !important
}

.overlay-danger.overlay-lighten-5 {
    background: #f8c6c6;
    background: hsla(0, 78%, 87%, .6)
}

.text-danger.text-lighten-4 {
    color: #f5afaf !important
}

.bg-danger.bg-lighten-4 {
    background-color: #f5afaf !important
}

.border-danger.border-lighten-4 {
    border: 1px solid #f5afaf !important
}

.border-top-danger.border-top-lighten-4 {
    border-top: 1px solid #f5afaf !important
}

.border-bottom-danger.border-bottom-lighten-4 {
    border-bottom: 1px solid #f5afaf !important
}

.border-left-danger.border-left-lighten-4 {
    border-left: 1px solid #f5afaf !important
}

.border-right-danger.border-right-lighten-4 {
    border-right: 1px solid #f5afaf !important
}

.overlay-danger.overlay-lighten-4 {
    background: #f5afaf;
    background: hsla(0, 78%, 82%, .6)
}

.text-danger.text-lighten-3 {
    color: #f29899 !important
}

.bg-danger.bg-lighten-3 {
    background-color: #f29899 !important
}

.border-danger.border-lighten-3 {
    border: 1px solid #f29899 !important
}

.border-top-danger.border-top-lighten-3 {
    border-top: 1px solid #f29899 !important
}

.border-bottom-danger.border-bottom-lighten-3 {
    border-bottom: 1px solid #f29899 !important
}

.border-left-danger.border-left-lighten-3 {
    border-left: 1px solid #f29899 !important
}

.border-right-danger.border-right-lighten-3 {
    border-right: 1px solid #f29899 !important
}

.overlay-danger.overlay-lighten-3 {
    background: #f29899;
    background: rgba(242, 152, 153, .6)
}

.text-danger.text-lighten-2 {
    color: #f08182 !important
}

.bg-danger.bg-lighten-2 {
    background-color: #f08182 !important
}

.border-danger.border-lighten-2 {
    border: 1px solid #f08182 !important
}

.border-top-danger.border-top-lighten-2 {
    border-top: 1px solid #f08182 !important
}

.border-bottom-danger.border-bottom-lighten-2 {
    border-bottom: 1px solid #f08182 !important
}

.border-left-danger.border-left-lighten-2 {
    border-left: 1px solid #f08182 !important
}

.border-right-danger.border-right-lighten-2 {
    border-right: 1px solid #f08182 !important
}

.overlay-danger.overlay-lighten-2 {
    background: #f08182;
    background: rgba(240, 129, 130, .6)
}

.text-danger.text-lighten-1 {
    color: #ed6b6c !important
}

.bg-danger.bg-lighten-1 {
    background-color: #ed6b6c !important
}

.border-danger.border-lighten-1 {
    border: 1px solid #ed6b6c !important
}

.border-top-danger.border-top-lighten-1 {
    border-top: 1px solid #ed6b6c !important
}

.border-bottom-danger.border-bottom-lighten-1 {
    border-bottom: 1px solid #ed6b6c !important
}

.border-left-danger.border-left-lighten-1 {
    border-left: 1px solid #ed6b6c !important
}

.border-right-danger.border-right-lighten-1 {
    border-right: 1px solid #ed6b6c !important
}

.overlay-danger.overlay-lighten-1 {
    background: #ed6b6c;
    background: rgba(237, 107, 108, .6)
}

.bg-danger {
    background-color: #ea5455 !important
}

.bg-danger .card-footer, .bg-danger .card-header {
    background-color: transparent
}

.alert-danger {
    background: rgba(234, 84, 85, .12) !important;
    color: #ea5455 !important
}

.alert-danger .alert-heading {
    box-shadow: 0 6px 15px -7px rgba(234, 84, 85, .4)
}

.alert-danger .alert-link {
    color: #e73d3e !important
}

.alert-danger .close, .bg-light-danger {
    color: #ea5455 !important
}

.bg-light-danger {
    background: rgba(234, 84, 85, .12) !important
}

.bg-light-danger.fc-h-event, .bg-light-danger.fc-v-event {
    border-color: rgba(234, 84, 85, .1)
}

.bg-light-danger .fc-daygrid-event-dot, .bg-light-danger .fc-list-event-dot {
    border-color: #ea5455 !important
}

.bg-light-danger.fc-list-event:hover td {
    background: rgba(234, 84, 85, .1) !important
}

.bg-light-danger.fc-list-event .fc-list-event-title {
    color: #6e6b7b
}

.avatar.bg-light-danger {
    color: #ea5455 !important
}

.border-danger {
    border: 1px solid #ea5455 !important
}

.border-top-danger {
    border-top: 1px solid #ea5455
}

.border-bottom-danger {
    border-bottom: 1px solid #ea5455
}

.border-left-danger {
    border-left: 1px solid #ea5455
}

.border-right-danger {
    border-right: 1px solid #ea5455
}

.badge-danger.badge-glow, .bg-danger.badge-glow, .border-danger.badge-glow {
    box-shadow: 0 0 10px #ea5455
}

.badge.badge-light-danger {
    background-color: rgba(234, 84, 85, .12);
    color: #ea5455 !important
}

.overlay-danger {
    background: #ea5455;
    background: rgba(234, 84, 85, .6)
}

.btn-danger {
    border-color: #ea5455 !important;
    background-color: #ea5455 !important;
    color: #fff !important
}

.btn-danger.active, .btn-danger:active, .btn-danger:focus {
    color: #fff;
    background-color: #e73d3e !important
}

.btn-danger:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px #ea5455
}

.btn-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-flat-danger {
    background-color: transparent;
    color: #ea5455
}

.btn-flat-danger:hover {
    color: #ea5455
}

.btn-flat-danger:hover:not(.disabled):not(:disabled) {
    background-color: rgba(234, 84, 85, .12)
}

.btn-flat-danger.active, .btn-flat-danger:active, .btn-flat-danger:focus {
    background-color: rgba(234, 84, 85, .2);
    color: #ea5455
}

.btn-flat-danger.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.btn-relief-danger {
    background-color: #ea5455;
    box-shadow: inset 0 -3px 0 0 rgba(34, 41, 47, .2);
    color: #fff;
    transition: all .2s ease
}

.btn-relief-danger:hover:not(.disabled):not(:disabled) {
    background-color: #ed6b6c
}

.btn-relief-danger.active, .btn-relief-danger:active, .btn-relief-danger:focus {
    background-color: #e73d3e
}

.btn-relief-danger:hover {
    color: #fff
}

.btn-relief-danger.active, .btn-relief-danger:active {
    outline: none;
    box-shadow: none;
    transform: translateY(3px)
}

.btn-outline-danger {
    border: 1px solid #ea5455 !important;
    background-color: transparent;
    color: #ea5455
}

.btn-outline-danger:hover:not(.disabled):not(:disabled) {
    background-color: rgba(234, 84, 85, .04);
    color: #ea5455
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus {
    box-shadow: none
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled):focus {
    background-color: rgba(234, 84, 85, .2);
    color: #ea5455
}

.btn-outline-danger.dropdown-toggle:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E")
}

.show>.btn-outline-danger.dropdown-toggle {
    background-color: rgba(234, 84, 85, .2);
    color: #ea5455
}

.btn-flat-danger.waves-effect .waves-ripple, .btn-outline-danger.waves-effect .waves-ripple {
    background: radial-gradient(rgba(234, 84, 85, .2) 0, rgba(234, 84, 85, .3) 40%, rgba(234, 84, 85, .4) 50%, rgba(234, 84, 85, .5) 60%, hsla(0, 0%, 100%, 0) 70%)
}

.bullet.bullet-danger {
    background-color: #ea5455
}

.modal.modal-danger .modal-header .modal-title {
    color: #ea5455
}

.modal.modal-danger .modal-header .close {
    color: #ea5455 !important
}

.pagination-danger .page-item.active .page-link {
    background: #ea5455 !important;
    color: #fff
}

.pagination-danger .page-item.active .page-link:hover {
    color: #fff
}

.pagination-danger .page-item .page-link:hover {
    color: #ea5455
}

.pagination-danger .page-item.next-item .page-link:hover, .pagination-danger .page-item.prev-item .page-link:hover {
    background: #ea5455;
    color: #fff
}

.pagination-danger .page-item.next-item .page-link:active:after, .pagination-danger .page-item.next-item .page-link:hover:after, .pagination-danger .page-item.next .page-link:active:after, .pagination-danger .page-item.next .page-link:hover:after {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-right'%3E%3Cpolyline points='9 18 15 12 9 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.pagination-danger .page-item.prev-item .page-link:active:before, .pagination-danger .page-item.prev-item .page-link:hover:before, .pagination-danger .page-item.prev .page-link:active:before, .pagination-danger .page-item.prev .page-link:hover:before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23ea5455' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-left'%3E%3Cpolyline points='15 18 9 12 15 6'%3E%3C/polyline%3E%3C/svg%3E") !important
}

.nav-pill-danger .nav-item .nav-link.active {
    color: #fff;
    background-color: #ea5455 !important;
    border-color: #ea5455;
    box-shadow: 0 4px 18px -4px rgba(234, 84, 85, .65)
}

.progress-bar-danger {
    background-color: rgba(234, 84, 85, .12)
}

.progress-bar-danger .progress-bar {
    background-color: #ea5455
}

.timeline .timeline-point-danger {
    border-color: #ea5455 !important
}

.timeline .timeline-point-danger i, .timeline .timeline-point-danger svg {
    stroke: #ea5455 !important
}

.timeline .timeline-point-danger.timeline-point-indicator {
    background-color: #ea5455 !important
}

.timeline .timeline-point-danger.timeline-point-indicator:before {
    background: rgba(234, 84, 85, .12) !important
}

.divider.divider-danger .divider-text:after, .divider.divider-danger .divider-text:before {
    border-color: #ea5455 !important
}

input:focus~.bg-danger {
    box-shadow: 0 0 0 .075rem #fff, 0 0 0 .21rem #ea5455 !important
}

.custom-control-danger .custom-control-input:active~.custom-control-label:before, .custom-control-danger .custom-control-input:checked~.custom-control-label:before {
    border-color: #ea5455;
    background-color: #ea5455
}

.custom-control-danger.custom-checkbox .custom-control-input:active~.custom-control-label:before, .custom-control-danger.custom-checkbox .custom-control-input:checked~.custom-control-label:before, .custom-control-danger.custom-checkbox .custom-control-input:focus~.custom-control-label:before, .custom-control-danger.custom-radio .custom-control-input:active~.custom-control-label:before, .custom-control-danger.custom-radio .custom-control-input:checked~.custom-control-label:before, .custom-control-danger.custom-radio .custom-control-input:focus~.custom-control-label:before {
    box-shadow: 0 2px 4px 0 rgba(234, 84, 85, .4) !important
}

.custom-control-danger .custom-control-input:disabled:checked~.custom-control-label:before {
    background-color: rgba(234, 84, 85, .65) !important;
    border: none;
    box-shadow: none !important
}

.custom-control-danger .custom-control-input:focus~.custom-control-label:before {
    border-color: #ea5455 !important
}

.custom-switch-danger .custom-control-input:checked~.custom-control-label:before {
    background-color: #ea5455 !important;
    color: #fff;
    transition: all .2s ease-out
}

.select2-danger .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: #ea5455 !important;
    border-color: #ea5455 !important
}

.text-danger.text-darken-1 {
    color: #e73d3e !important
}

.bg-danger.bg-darken-1 {
    background-color: #e73d3e !important
}

.border-danger.border-darken-1 {
    border: 1px solid #e73d3e !important
}

.border-top-danger.border-top-darken-1 {
    border-top: 1px solid #e73d3e !important
}

.border-bottom-danger.border-bottom-darken-1 {
    border-bottom: 1px solid #e73d3e !important
}

.border-left-danger.border-left-darken-1 {
    border-left: 1px solid #e73d3e !important
}

.border-right-danger.border-right-darken-1 {
    border-right: 1px solid #e73d3e !important
}

.overlay-danger.overlay-darken-1 {
    background: #e73d3e;
    background: rgba(231, 61, 62, .6)
}

.text-danger.text-darken-2 {
    color: #e42728 !important
}

.bg-danger.bg-darken-2 {
    background-color: #e42728 !important
}

.border-danger.border-darken-2 {
    border: 1px solid #e42728 !important
}

.border-top-danger.border-top-darken-2 {
    border-top: 1px solid #e42728 !important
}

.border-bottom-danger.border-bottom-darken-2 {
    border-bottom: 1px solid #e42728 !important
}

.border-left-danger.border-left-darken-2 {
    border-left: 1px solid #e42728 !important
}

.border-right-danger.border-right-darken-2 {
    border-right: 1px solid #e42728 !important
}

.overlay-danger.overlay-darken-2 {
    background: #e42728;
    background: rgba(228, 39, 40, .6)
}

.text-danger.text-darken-3 {
    color: #d71a1c !important
}

.bg-danger.bg-darken-3 {
    background-color: #d71a1c !important
}

.border-danger.border-darken-3 {
    border: 1px solid #d71a1c !important
}

.border-top-danger.border-top-darken-3 {
    border-top: 1px solid #d71a1c !important
}

.border-bottom-danger.border-bottom-darken-3 {
    border-bottom: 1px solid #d71a1c !important
}

.border-left-danger.border-left-darken-3 {
    border-left: 1px solid #d71a1c !important
}

.border-right-danger.border-right-darken-3 {
    border-right: 1px solid #d71a1c !important
}

.overlay-danger.overlay-darken-3 {
    background: #d71a1c;
    background: rgba(215, 26, 28, .6)
}

.text-danger.text-darken-4 {
    color: #c01819 !important
}

.bg-danger.bg-darken-4 {
    background-color: #c01819 !important
}

.border-danger.border-darken-4 {
    border: 1px solid #c01819 !important
}

.border-top-danger.border-top-darken-4 {
    border-top: 1px solid #c01819 !important
}

.border-bottom-danger.border-bottom-darken-4 {
    border-bottom: 1px solid #c01819 !important
}

.border-left-danger.border-left-darken-4 {
    border-left: 1px solid #c01819 !important
}

.border-right-danger.border-right-darken-4 {
    border-right: 1px solid #c01819 !important
}

.overlay-danger.overlay-darken-4 {
    background: #c01819;
    background: rgba(192, 24, 25, .6)
}

.text-danger.text-accent-1 {
    color: #ffeef1 !important
}

.bg-danger.bg-accent-1 {
    background-color: #ffeef1 !important
}

.border-danger.border-accent-1 {
    border: 1px solid #ffeef1 !important
}

.border-top-danger.border-top-accent-1 {
    border-top: 1px solid #ffeef1 !important
}

.border-bottom-danger.border-bottom-accent-1 {
    border-bottom: 1px solid #ffeef1 !important
}

.border-left-danger.border-left-accent-1 {
    border-left: 1px solid #ffeef1 !important
}

.border-right-danger.border-right-accent-1 {
    border-right: 1px solid #ffeef1 !important
}

.overlay-danger.overlay-accent-1 {
    background: #ffeef1;
    background: rgba(255, 238, 241, .6)
}

.text-danger.text-accent-2 {
    color: #ffd6db !important
}

.bg-danger.bg-accent-2 {
    background-color: #ffd6db !important
}

.border-danger.border-accent-2 {
    border: 1px solid #ffd6db !important
}

.border-top-danger.border-top-accent-2 {
    border-top: 1px solid #ffd6db !important
}

.border-bottom-danger.border-bottom-accent-2 {
    border-bottom: 1px solid #ffd6db !important
}

.border-left-danger.border-left-accent-2 {
    border-left: 1px solid #ffd6db !important
}

.border-right-danger.border-right-accent-2 {
    border-right: 1px solid #ffd6db !important
}

.overlay-danger.overlay-accent-2 {
    background: #ffd6db;
    background: rgba(255, 214, 219, .6)
}

.text-danger.text-accent-3 {
    color: #ffecee !important
}

.bg-danger.bg-accent-3 {
    background-color: #ffecee !important
}

.border-danger.border-accent-3 {
    border: 1px solid #ffecee !important
}

.border-top-danger.border-top-accent-3 {
    border-top: 1px solid #ffecee !important
}

.border-bottom-danger.border-bottom-accent-3 {
    border-bottom: 1px solid #ffecee !important
}

.border-left-danger.border-left-accent-3 {
    border-left: 1px solid #ffecee !important
}

.border-right-danger.border-right-accent-3 {
    border-right: 1px solid #ffecee !important
}

.overlay-danger.overlay-accent-3 {
    background: #ffecee;
    background: rgba(255, 236, 238, .6)
}

.text-danger.text-accent-4 {
    color: #ffd3d7 !important
}

.bg-danger.bg-accent-4 {
    background-color: #ffd3d7 !important
}

.border-danger.border-accent-4 {
    border: 1px solid #ffd3d7 !important
}

.border-top-danger.border-top-accent-4 {
    border-top: 1px solid #ffd3d7 !important
}

.border-bottom-danger.border-bottom-accent-4 {
    border-bottom: 1px solid #ffd3d7 !important
}

.border-left-danger.border-left-accent-4 {
    border-left: 1px solid #ffd3d7 !important
}

.border-right-danger.border-right-accent-4 {
    border-right: 1px solid #ffd3d7 !important
}

.overlay-danger.overlay-accent-4 {
    background: #ffd3d7;
    background: rgba(255, 211, 215, .6)
}

.bg-gradient-dark, .btn-gradient-dark {
    color: #fff;
    transition: all .2s ease;
    background-image: linear-gradient(47deg, #4b4b4b, #1e1e1e);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.dark-layout .bg-gradient-dark, .dark-layout .btn-gradient-dark {
    background-image: linear-gradient(47deg, #1e1e1e, #4b4b4b);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-dark:active, .bg-gradient-dark:hover, .btn-gradient-dark:active, .btn-gradient-dark:hover {
    color: #fff
}

.bg-gradient-dark:hover:not(.disabled):not(:disabled), .btn-gradient-dark:hover:not(.disabled):not(:disabled) {
    transform: translateY(-2px)
}

.bg-gradient-dark:active, .btn-gradient-dark:active {
    transform: translateY(0)
}

.bg-gradient-dark:active, .bg-gradient-dark:focus, .btn-gradient-dark:active, .btn-gradient-dark:focus {
    background-image: linear-gradient(47deg, #1e1e1e, #4b4b4b);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-primary, .btn-gradient-primary {
    color: #fff;
    transition: all .2s ease;
    background-image: linear-gradient(47deg, #7367f0, #9e95f5);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-primary:active, .bg-gradient-primary:hover, .btn-gradient-primary:active, .btn-gradient-primary:hover {
    color: #fff
}

.bg-gradient-primary:hover:not(.disabled):not(:disabled), .btn-gradient-primary:hover:not(.disabled):not(:disabled) {
    transform: translateY(-2px)
}

.bg-gradient-primary:active, .btn-gradient-primary:active {
    transform: translateY(0)
}

.bg-gradient-primary:active, .bg-gradient-primary:focus, .btn-gradient-primary:active, .btn-gradient-primary:focus {
    background-image: linear-gradient(47deg, #4839eb, #7367f0);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-secondary, .btn-gradient-secondary {
    color: #fff;
    transition: all .2s ease;
    background-image: linear-gradient(47deg, #82868b, #9ca0a4);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-secondary:active, .bg-gradient-secondary:hover, .btn-gradient-secondary:active, .btn-gradient-secondary:hover {
    color: #fff
}

.bg-gradient-secondary:hover:not(.disabled):not(:disabled), .btn-gradient-secondary:hover:not(.disabled):not(:disabled) {
    transform: translateY(-2px)
}

.bg-gradient-secondary:active, .btn-gradient-secondary:active {
    transform: translateY(0)
}

.bg-gradient-secondary:active, .bg-gradient-secondary:focus, .btn-gradient-secondary:active, .btn-gradient-secondary:focus {
    background-image: linear-gradient(47deg, #696d71, #82868b);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-success, .btn-gradient-success {
    color: #fff;
    transition: all .2s ease;
    background-image: linear-gradient(47deg, #28c76f, #48da89);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-success:active, .bg-gradient-success:hover, .btn-gradient-success:active, .btn-gradient-success:hover {
    color: #fff
}

.bg-gradient-success:hover:not(.disabled):not(:disabled), .btn-gradient-success:hover:not(.disabled):not(:disabled) {
    transform: translateY(-2px)
}

.bg-gradient-success:active, .btn-gradient-success:active {
    transform: translateY(0)
}

.bg-gradient-success:active, .bg-gradient-success:focus, .btn-gradient-success:active, .btn-gradient-success:focus {
    background-image: linear-gradient(47deg, #1f9d57, #28c76f);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-info, .btn-gradient-info {
    color: #fff;
    transition: all .2s ease;
    background-image: linear-gradient(47deg, #00cfe8, #1ce7ff);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-info:active, .bg-gradient-info:hover, .btn-gradient-info:active, .btn-gradient-info:hover {
    color: #fff
}

.bg-gradient-info:hover:not(.disabled):not(:disabled), .btn-gradient-info:hover:not(.disabled):not(:disabled) {
    transform: translateY(-2px)
}

.bg-gradient-info:active, .btn-gradient-info:active {
    transform: translateY(0)
}

.bg-gradient-info:active, .bg-gradient-info:focus, .btn-gradient-info:active, .btn-gradient-info:focus {
    background-image: linear-gradient(47deg, #00a1b5, #00cfe8);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-warning, .btn-gradient-warning {
    color: #fff;
    transition: all .2s ease;
    background-image: linear-gradient(47deg, #ff9f43, #ffb976);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-warning:active, .bg-gradient-warning:hover, .btn-gradient-warning:active, .btn-gradient-warning:hover {
    color: #fff
}

.bg-gradient-warning:hover:not(.disabled):not(:disabled), .btn-gradient-warning:hover:not(.disabled):not(:disabled) {
    transform: translateY(-2px)
}

.bg-gradient-warning:active, .btn-gradient-warning:active {
    transform: translateY(0)
}

.bg-gradient-warning:active, .bg-gradient-warning:focus, .btn-gradient-warning:active, .btn-gradient-warning:focus {
    background-image: linear-gradient(47deg, #ff8510, #ff9f43);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-danger, .btn-gradient-danger {
    color: #fff;
    transition: all .2s ease;
    background-image: linear-gradient(47deg, #ea5455, #f08182);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.bg-gradient-danger:active, .bg-gradient-danger:hover, .btn-gradient-danger:active, .btn-gradient-danger:hover {
    color: #fff
}

.bg-gradient-danger:hover:not(.disabled):not(:disabled), .btn-gradient-danger:hover:not(.disabled):not(:disabled) {
    transform: translateY(-2px)
}

.bg-gradient-danger:active, .btn-gradient-danger:active {
    transform: translateY(0)
}

.bg-gradient-danger:active, .bg-gradient-danger:focus, .btn-gradient-danger:active, .btn-gradient-danger:focus {
    background-image: linear-gradient(47deg, #e42728, #ea5455);
    background-repeat: repeat-x;
    background-repeat: repeat
}

.ngx-datatable.bootstrap.core-bootstrap.height-70-vh {
    height: 70vh !important
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-header {
    background-color: #f3f2f7;
    text-transform: uppercase;
    height: 40px !important;
    border-top: 1px solid #ebe9f1
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-cell {
    border-bottom: 0;
    padding: .75rem 1rem;
    font-weight: 700;
    font-size: .8rem
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-header .datatable-header-cell-template-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    letter-spacing: 1px
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-header .sort-btn:before {
    font-size: 1.3rem
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row {
    border-color: #ebe9f1
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row.datatable-row-even {
    background-color: transparent;
    background-color: initial
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row.active {
    background-color: #f6f6f6;
    color: #6e6b7b
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row .datatable-body-cell-label .cell-line-height {
    line-height: 1.25
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-row .datatable-body-cell {
    padding: .75rem 1rem;
    display: block;
    align-items: center
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer {
    background-color: #fff;
    color: #636363;
    overflow: hidden;
    margin-bottom: 1rem
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager a {
    color: #636363;
    line-height: 20px
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active:hover {
    vertical-align: sub
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active:hover a, .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled).active a {
    background-color: #7367f0 !important;
    color: #fff !important;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 5px
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
    background-color: transparent !important;
    color: #7367f0 !important
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li {
    margin: 2px 0 11px !important
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li:not(.disabled):hover a {
    background-color: #fff;
    color: #636363
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li.disabled a {
    color: #b9b9c3
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager .pager {
    background-color: #f3f2f7;
    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
    height: 36px
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-footer .page-count {
    color: #b9b9c3
}

.ngx-datatable.bootstrap.core-bootstrap.header-height-50 .datatable-header {
    height: 50px !important
}

.ngx-datatable.bootstrap.core-bootstrap .datatable-body-cell {
    overflow: visible !important
}

@media (max-width:768px) {
    .page-count {
        display: none
    }

    .datatable-pager {
        text-align: center !important;
        margin: 0 !important
    }
}

@media screen and (max-width:800px) {
    .desktop-hidden {
        display: inline;
        display: initial
    }

    .mobile-hidden {
        display: none
    }
}

@media screen and (min-width:800px) {
    .desktop-hidden {
        display: none
    }

    .mobile-hidden {
        display: inline;
        display: initial
    }
}

.dark-layout .datatable-header, .dark-layout .datatable-header-inner {
    background-color: #343d55 !important;
    border-top: 1px solid #3b4253 !important
}

.dark-layout .ngx-datatable.bootstrap.core-bootstrap .datatable-footer {
    background-color: #283046 !important
}

.dark-layout .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager ul li.disabled a {
    color: #636363
}

.dark-layout .ngx-datatable.bootstrap.core-bootstrap .datatable-footer .datatable-pager a {
    color: #b4b7bd
}

.dark-layout .datatable-body-row {
    border-color: #3b4253 !important
}

.dark-layout .datatable-body-row.active {
    background-color: #283046 !important;
    color: inherit !important
}

.dark-layout .datatable-body {
    background-color: #283046 !important
}

.dark-layout .pager {
    background-color: #242b3d !important
}

.datatable-scroll {
    width: auto !important;
}